import { Pipe, PipeTransform } from "@angular/core";
import { OutGetItemDetails, OutputGetAllItemSearchDto } from "../api";

@Pipe({
  name: "discount",
})
export class DiscountPipe implements PipeTransform {
  transform(
    value: any,
    args?: OutputGetAllItemSearchDto | OutGetItemDetails
  ): any {
    const price = args.isOffer
      ? args.price - (args.price * args.offerPercentage) / 100
      : args.price;
    return price;
  }
}
