import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { AppConfigService } from "./AppConfigService";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  AppConfigService.loadConfig().then(() => {
    return platformBrowserDynamic()
      .bootstrapModule(AppModule)

      .catch((err) => console.error(err));
  });
});
