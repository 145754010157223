<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span> -->
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product-details/', product.id]">
        <img
          [defaultImage]="
            ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
          "
          [lazyLoad]="ImageSrc ? ImageSrc : product.itemImage[0]"
          class="img-fluid lazy-loading"
        />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage && product.itemImage[1]">
      <a [routerLink]="['/shop/product-details/', product.id]">
        <img
          [src]="ImageSrc ? ImageSrc : product.itemImage[1]"
          class="img-fluid lazy-loading"
        />
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li
        class="grid_thumb_img"
        [class.active]="ImageSrc == image"
        *ngFor="let image of product.itemImage"
      >
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">
          <img [lazyLoad]="image" />
        </a>
      </li>
    </ul>
    <!-- <div class="cart-info cart-wrap">
      <a
        href="javascript:void(0)"
        title="Add to cart"
        (click)="CartModal.openModal(product)"
        *ngIf="cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Add to cart"
        (click)="addToCart(product)"
        *ngIf="!cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Add to Wishlist"
        (click)="addToWishlist(product)"
      >
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Quick View"
        (click)="QuickView.openModal()"
      >
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Compare"
        (click)="addToCompare(product)"
      >
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div> -->
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/product-details/', product.id]">
        <h6>{{ product?.name | titlecase }}</h6>
      </a>
      <p>{{ product?.name }}</p>
      <h4>
        {{
          product?.isOffer
            ? (product?.offerPrice * currency?.price
              | discount : product
              | currency : currency?.currency : "symbol")
            : (product?.offerPrice * currency?.price
              | discount : product
              | currency : currency?.currency : "symbol")
        }}
        <del *ngIf="product?.isOffer"
          ><span class="money">
            {{
              product?.price * currency?.price
                | currency : currency?.currency : "symbol"
            }}</span
          ></del
        >
      </h4>

      <ul class="color-variant" *ngIf="product.itemColor.length > 0">
        <li
          *ngFor="let color of product.itemColor"
          [ngStyle]="{ 'background-color': color.colorCode }"
          [ngClass]="{
            'selected-color': color.id === selectedColor,
            'unselected-color': color.id != selectedColor
          }"
          (click)="ChangeVariants(color, product)"
        ></li>
      </ul>

      <button class="choose-btn" (click)="selectProduct()">Choose</button>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view
  #quickView
  [product]="product"
  [currency]="currency"
></app-quick-view>
<app-cart-modal
  #cartModal
  [product]="product"
  [currency]="currency"
  *ngIf="cartModal"
></app-cart-modal>
