<!-- filter scope -->
<mat-grid-list class="filter-scope-container" cols="2" rowHeight="48px">
  <mat-grid-tile>
    <button type="button" mat-icon-button [disabled]="filterScope==='ALL'" (click)="toggleScope('ALL')"><i [ngClass]="{'active-icon':filterScope==='ALL'}" class="material-icons icons">photo_size_select_actual</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" mat-icon-button disabled><i [ngClass]="{'active-icon':filterScope==='SINGLE'}" class="material-icons icons">photo_size_select_large</i></button>
  </mat-grid-tile>
</mat-grid-list>

<!-- Advanced setting panel -->
<div *ngIf="panelType==='ADVANCED'" class="panel-container">
  <!-- brightness amount -->
  <div class="picker-container">
    <button type="button" mat-icon-button matTooltip="Brightness" matTooltipPosition="above" (click)="onSetToDefault('brightness')" [disabled]="filterValues['brightness']===0">
      <i class="material-icons" [ngClass]="{'icons':filterValues['brightness']!==0}">brightness_medium</i>
    </button>
    <div class="slider-wrapper">
      <mat-slider min="-0.5" step="0.05" max="0.5" [(ngModel)]="filterValues['brightness']" (change)="onFilterUpdate()" class="slider "></mat-slider>
    </div>
  </div>

  <!-- contrast amount -->
  <div class="picker-container">
    <button type="button" mat-icon-button matTooltip="Contrast" matTooltipPosition="above" (click)="onSetToDefault('contrast')" [disabled]="filterValues['contrast']===0">
    <i class="material-icons" [ngClass]="{'icons':filterValues['contrast']!==0}">brightness_2</i>
  </button>
    <div class="slider-wrapper">
      <mat-slider min="-0.5" step="0.05" max="0.5" [(ngModel)]="filterValues['contrast']" (change)="onFilterUpdate()" class="slider "></mat-slider>
    </div>
  </div>

  <!-- saturation amount -->
  <div class="picker-container">
    <button type="button" mat-icon-button matTooltip="Saturation" matTooltipPosition="above" (click)="onSetToDefault('saturation')" [disabled]="filterValues['saturation']===0">
    <i class="material-icons" [ngClass]="{'icons':filterValues['saturation']!==0}">invert_colors</i>
  </button>
    <div class="slider-wrapper">
      <mat-slider min="-1" step="0.05" max="1" [(ngModel)]="filterValues['saturation']" (change)="onFilterUpdate()" class="slider "></mat-slider>
    </div>
  </div>

  <!-- Hue amount -->
  <div class="picker-container">
    <button type="button" mat-icon-button matTooltip="Hue" matTooltipPosition="above" (click)="onSetToDefault('hue')" [disabled]="filterValues['hue']===0">
    <i class="material-icons" [ngClass]="{'icons':filterValues['hue']!==0}">colorize</i>
  </button>
    <div class="slider-wrapper">
      <mat-slider min="-2" step="0.002" max="2" [(ngModel)]="filterValues['hue']" (change)="onFilterUpdate()" class="slider"></mat-slider>
    </div>
  </div>

  <!-- Noise amount -->
  <div class="picker-container">
    <button type="button" mat-icon-button matTooltip="Noise" matTooltipPosition="above" (click)="onSetToDefault('noise')" [disabled]="filterValues['noise']===0">
    <i class="material-icons" [ngClass]="{'icons':filterValues['noise']!==0}">graphic_eq</i>
  </button>
    <div class="slider-wrapper">
      <mat-slider min="0" step="1" max="1000" [(ngModel)]="filterValues['noise']" (change)="onFilterUpdate()" class="slider"></mat-slider>
    </div>
  </div>

  <!-- blur amount -->
  <div class="picker-container">
    <button type="button" mat-icon-button matTooltip="Blur" matTooltipPosition="above" (click)="onSetToDefault('blur')" [disabled]="filterValues['blur']===0">
    <i class="material-icons" [ngClass]="{'icons':filterValues['blur']!==0}">blur_circular</i>
  </button>
    <div class="slider-wrapper">
      <mat-slider min="0" step="0.01" max="1" [(ngModel)]="filterValues['blur']" (change)="onFilterUpdate()" class="slider"></mat-slider>
    </div>
  </div>

  <!-- pixelate amount -->
  <div class="picker-container">
    <button type="button" mat-icon-button matTooltip="Pixelate" matTooltipPosition="above" (click)="onSetToDefault('pixelate')" [disabled]="filterValues['pixelate']===0">
    <i class="material-icons" [ngClass]="{'icons':filterValues['pixelate']!==0}">view_comfy</i>
  </button>
    <div class="slider-wrapper">
      <mat-slider min="0" step="1" max="20" [(ngModel)]="filterValues['pixelate']" (change)="onFilterUpdate()" class="slider"></mat-slider>
    </div>
  </div>

  <!-- preset panel button -->
  <div class="button-container">
    <button type="button" mat-raised-button color="primary" class="button" (click)="onPanelChange('PRESET')">SHOW PRESET</button>
  </div>
</div>

<div *ngIf="panelType==='PRESET'" class="panel-container">

  <!-- advanced panel button -->
  <div class="button-container">
    <button type="button" mat-raised-button [color]="filterValues.sharpen?'accent':'warn'" class="button" (click)="togglePreset('sharpen')">SHARPEN</button>
  </div>
  <div class="button-container">
    <button type="button" mat-raised-button [color]="filterValues.emboss?'accent':'warn'" class="button" (click)="togglePreset('emboss')">EMBOSS</button>
  </div>
  <div class="button-container">
    <button type="button" mat-raised-button [color]="filterValues.grayscale?'accent':'warn'" class="button" (click)="togglePreset('grayscale')">GRAYSCALE</button>
  </div>
  <div class="button-container">
    <button type="button" mat-raised-button [color]="filterValues.vintage?'accent':'warn'" class="button" (click)="togglePreset('vintage')">VINTAGE</button>
  </div>
  <div class="button-container">
    <button type="button" mat-raised-button [color]="filterValues.sepia?'accent':'warn'" class="button" (click)="togglePreset('sepia')">SEPIA</button>
  </div>
  <div class="button-container">
    <button type="button" mat-raised-button [color]="filterValues.polaroid?'accent':'warn'" class="button" (click)="togglePreset('polaroid')">POLAROID</button>
  </div>
  <div class="button-container">
    <button type="button" mat-raised-button color="primary" class="button" (click)="onPanelChange('ADVANCED')">ADVANCED SETTINGS</button>
  </div>
</div>