<div class="form-group p-relative">
  <label class="text-lable" *ngIf="showText" for="{{ text }}">{{
    text + (isRequired ? "*" : "")
  }}</label>
  <input
    [ngClass]="{ invalid: control.touched ? errors[0] : false }"
    #input
    type="{{ !haveHide || showPassword ? 'text' : 'password' }}"
    [(ngModel)]="value"
    class="form-control"
    (blur)="onChange($event, input.value)"
    (keyup)="onChange($event, input.value)"
    id="{{ text }}"
    placeholder="{{ text }}"
  />
  <i
    *ngIf="haveHide"
    (click)="showPassword = !showPassword"
    [ngClass]="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"
    class="fa fa-eye-slash form-control-feedback"
    aria-hidden="true"
  ></i>
  <small id="passwordHelpBlock" class="form-text text-muted">
    {{ hint }}
  </small>

  <div class="invalid-feedback">
    {{ (control.touched ? errors[0] : "") | translate }}
  </div>
</div>
