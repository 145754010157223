/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateStylistDto } from '../models/create-stylist-dto';
import { InputForgetPasswordDto } from '../models/input-forget-password-dto';
import { InputResetPasswordDto } from '../models/input-reset-password-dto';
import { InputSignInStylistDto } from '../models/input-sign-in-stylist-dto';
import { InputStylistRefreshTokenDto } from '../models/input-stylist-refresh-token-dto';
import { InputVerficationPasswordDto } from '../models/input-verfication-password-dto';
import { OutputForgetPasswordDto } from '../models/output-forget-password-dto';
import { OutputGetStylistByUserNameDto } from '../models/output-get-stylist-by-user-name-dto';
import { OutputSignInStylistDto } from '../models/output-sign-in-stylist-dto';
import { OutputStylistRefreshTokenDto } from '../models/output-stylist-refresh-token-dto';
import { OutputVerificationPasswordDto } from '../models/output-verification-password-dto';

@Injectable({
  providedIn: 'root',
})
export class StylistAccountManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation stylistAccountManagementCreateStylist
   */
  static readonly StylistAccountManagementCreateStylistPath = '/api/customer/StylistAccountManagement/CreateStylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementCreateStylist$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementCreateStylist$Plain$Response(params?: {
    context?: HttpContext
    body?: CreateStylistDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementCreateStylistPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementCreateStylist$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementCreateStylist$Plain(params?: {
    context?: HttpContext
    body?: CreateStylistDto
  }
): Observable<boolean> {

    return this.stylistAccountManagementCreateStylist$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementCreateStylist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementCreateStylist$Response(params?: {
    context?: HttpContext
    body?: CreateStylistDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementCreateStylistPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementCreateStylist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementCreateStylist(params?: {
    context?: HttpContext
    body?: CreateStylistDto
  }
): Observable<boolean> {

    return this.stylistAccountManagementCreateStylist$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistAccountManagementGetStylistByUserName
   */
  static readonly StylistAccountManagementGetStylistByUserNamePath = '/api/customer/StylistAccountManagement/GetStylistByUserName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementGetStylistByUserName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetStylistByUserName$Plain$Response(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistByUserNameDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementGetStylistByUserNamePath, 'get');
    if (params) {
      rb.query('UserName', params.UserName, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistByUserNameDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementGetStylistByUserName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetStylistByUserName$Plain(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<OutputGetStylistByUserNameDto> {

    return this.stylistAccountManagementGetStylistByUserName$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistByUserNameDto>) => r.body as OutputGetStylistByUserNameDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementGetStylistByUserName()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetStylistByUserName$Response(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistByUserNameDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementGetStylistByUserNamePath, 'get');
    if (params) {
      rb.query('UserName', params.UserName, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistByUserNameDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementGetStylistByUserName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetStylistByUserName(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<OutputGetStylistByUserNameDto> {

    return this.stylistAccountManagementGetStylistByUserName$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistByUserNameDto>) => r.body as OutputGetStylistByUserNameDto)
    );
  }

  /**
   * Path part for operation stylistAccountManagementGetCurrentStylist
   */
  static readonly StylistAccountManagementGetCurrentStylistPath = '/api/customer/StylistAccountManagement/GetCurrentStylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementGetCurrentStylist$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetCurrentStylist$Plain$Response(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistByUserNameDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementGetCurrentStylistPath, 'get');
    if (params) {
      rb.query('UserName', params.UserName, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistByUserNameDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementGetCurrentStylist$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetCurrentStylist$Plain(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<OutputGetStylistByUserNameDto> {

    return this.stylistAccountManagementGetCurrentStylist$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistByUserNameDto>) => r.body as OutputGetStylistByUserNameDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementGetCurrentStylist()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetCurrentStylist$Response(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistByUserNameDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementGetCurrentStylistPath, 'get');
    if (params) {
      rb.query('UserName', params.UserName, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistByUserNameDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementGetCurrentStylist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementGetCurrentStylist(params?: {
    UserName?: string;
    context?: HttpContext
  }
): Observable<OutputGetStylistByUserNameDto> {

    return this.stylistAccountManagementGetCurrentStylist$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistByUserNameDto>) => r.body as OutputGetStylistByUserNameDto)
    );
  }

  /**
   * Path part for operation stylistAccountManagementIsComplete
   */
  static readonly StylistAccountManagementIsCompletePath = '/api/customer/StylistAccountManagement/IsComplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementIsComplete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementIsComplete$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementIsCompletePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementIsComplete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementIsComplete$Plain(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.stylistAccountManagementIsComplete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementIsComplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementIsComplete$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementIsCompletePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementIsComplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistAccountManagementIsComplete(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.stylistAccountManagementIsComplete$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistAccountManagementStylistSignIn
   */
  static readonly StylistAccountManagementStylistSignInPath = '/api/customer/StylistAccountManagement/StylistSignIn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistSignIn$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistSignIn$Plain$Response(params?: {
    context?: HttpContext
    body?: InputSignInStylistDto
  }
): Observable<StrictHttpResponse<OutputSignInStylistDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistSignInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInStylistDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistSignIn$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistSignIn$Plain(params?: {
    context?: HttpContext
    body?: InputSignInStylistDto
  }
): Observable<OutputSignInStylistDto> {

    return this.stylistAccountManagementStylistSignIn$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInStylistDto>) => r.body as OutputSignInStylistDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistSignIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistSignIn$Response(params?: {
    context?: HttpContext
    body?: InputSignInStylistDto
  }
): Observable<StrictHttpResponse<OutputSignInStylistDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistSignInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInStylistDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistSignIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistSignIn(params?: {
    context?: HttpContext
    body?: InputSignInStylistDto
  }
): Observable<OutputSignInStylistDto> {

    return this.stylistAccountManagementStylistSignIn$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInStylistDto>) => r.body as OutputSignInStylistDto)
    );
  }

  /**
   * Path part for operation stylistAccountManagementStylistToken
   */
  static readonly StylistAccountManagementStylistTokenPath = '/api/customer/StylistAccountManagement/StylistToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistToken$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistToken$Plain$Response(params?: {
    context?: HttpContext
    body?: InputStylistRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputStylistRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputStylistRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistToken$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistToken$Plain(params?: {
    context?: HttpContext
    body?: InputStylistRefreshTokenDto
  }
): Observable<OutputStylistRefreshTokenDto> {

    return this.stylistAccountManagementStylistToken$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputStylistRefreshTokenDto>) => r.body as OutputStylistRefreshTokenDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistToken$Response(params?: {
    context?: HttpContext
    body?: InputStylistRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputStylistRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputStylistRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistToken(params?: {
    context?: HttpContext
    body?: InputStylistRefreshTokenDto
  }
): Observable<OutputStylistRefreshTokenDto> {

    return this.stylistAccountManagementStylistToken$Response(params).pipe(
      map((r: StrictHttpResponse<OutputStylistRefreshTokenDto>) => r.body as OutputStylistRefreshTokenDto)
    );
  }

  /**
   * Path part for operation stylistAccountManagementStylistForgetPassword
   */
  static readonly StylistAccountManagementStylistForgetPasswordPath = '/api/customer/StylistAccountManagement/StylistForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.stylistAccountManagementStylistForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistForgetPassword(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.stylistAccountManagementStylistForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * Path part for operation stylistAccountManagementStylistVerificationPinForgetPassword
   */
  static readonly StylistAccountManagementStylistVerificationPinForgetPasswordPath = '/api/customer/StylistAccountManagement/StylistVerificationPinForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistVerificationPinForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistVerificationPinForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistVerificationPinForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistVerificationPinForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.stylistAccountManagementStylistVerificationPinForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistVerificationPinForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistVerificationPinForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistVerificationPinForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistVerificationPinForgetPassword(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.stylistAccountManagementStylistVerificationPinForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * Path part for operation stylistAccountManagementStylistResetPassword
   */
  static readonly StylistAccountManagementStylistResetPasswordPath = '/api/customer/StylistAccountManagement/StylistResetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistResetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistResetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistResetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistResetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.stylistAccountManagementStylistResetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistAccountManagementStylistResetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistResetPassword$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistAccountManagementService.StylistAccountManagementStylistResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistAccountManagementStylistResetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistAccountManagementStylistResetPassword(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.stylistAccountManagementStylistResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
