import { Component, OnInit, Input } from "@angular/core";
import { CacheDataService } from "../services/cache-data.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() class: string = "footer-light"; // Default class
  @Input() themeLogo: string = "assets/img/logo.png"; // Default Logo

  public today: number = Date.now();

  constructor(public cacheDataService: CacheDataService) {}

  ngOnInit(): void {}
}
