/* tslint:disable */
/* eslint-disable */
export enum TaskStatus {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7
}
