<ng-template class="theme-modal" #sizeChart let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{ product?.name }}</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <img src="assets/images/size-chart.jpg" alt="" class="img-fluid" />
    </div>
  </div>
</ng-template>
