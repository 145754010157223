/* tslint:disable */
/* eslint-disable */
export enum ParameterAttributes {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $4 = 4,
  $8 = 8,
  $16 = 16,
  $4096 = 4096,
  $8192 = 8192,
  $16384 = 16384,
  $32768 = 32768,
  $61440 = 61440
}
