/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateClientDto } from '../models/create-client-dto';
import { InputClientRefreshTokenDto } from '../models/input-client-refresh-token-dto';
import { InputForgetPasswordDto } from '../models/input-forget-password-dto';
import { InputResetPasswordDto } from '../models/input-reset-password-dto';
import { InputSignInClientDto } from '../models/input-sign-in-client-dto';
import { InputVerficationPasswordDto } from '../models/input-verfication-password-dto';
import { OutputClientRefreshTokenDto } from '../models/output-client-refresh-token-dto';
import { OutputForgetPasswordDto } from '../models/output-forget-password-dto';
import { OutputSignInClientDto } from '../models/output-sign-in-client-dto';
import { OutputVerificationPasswordDto } from '../models/output-verification-password-dto';

@Injectable({
  providedIn: 'root',
})
export class ClientAccountManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientAccountManagementClientSignIn
   */
  static readonly ClientAccountManagementClientSignInPath = '/api/customer/ClientAccountManagement/ClientSignIn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientSignIn$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientSignIn$Plain$Response(params?: {
    context?: HttpContext
    body?: InputSignInClientDto
  }
): Observable<StrictHttpResponse<OutputSignInClientDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientSignInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInClientDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientSignIn$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientSignIn$Plain(params?: {
    context?: HttpContext
    body?: InputSignInClientDto
  }
): Observable<OutputSignInClientDto> {

    return this.clientAccountManagementClientSignIn$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInClientDto>) => r.body as OutputSignInClientDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientSignIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientSignIn$Response(params?: {
    context?: HttpContext
    body?: InputSignInClientDto
  }
): Observable<StrictHttpResponse<OutputSignInClientDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientSignInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInClientDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientSignIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientSignIn(params?: {
    context?: HttpContext
    body?: InputSignInClientDto
  }
): Observable<OutputSignInClientDto> {

    return this.clientAccountManagementClientSignIn$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInClientDto>) => r.body as OutputSignInClientDto)
    );
  }

  /**
   * Path part for operation clientAccountManagementClientForgetPassword
   */
  static readonly ClientAccountManagementClientForgetPasswordPath = '/api/customer/ClientAccountManagement/ClientForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.clientAccountManagementClientForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientForgetPassword(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.clientAccountManagementClientForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * Path part for operation clientAccountManagementClientVerificationPinForgetPassword
   */
  static readonly ClientAccountManagementClientVerificationPinForgetPasswordPath = '/api/customer/ClientAccountManagement/ClientVerificationPinForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientVerificationPinForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientVerificationPinForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientVerificationPinForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientVerificationPinForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.clientAccountManagementClientVerificationPinForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientVerificationPinForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientVerificationPinForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientVerificationPinForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientVerificationPinForgetPassword(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.clientAccountManagementClientVerificationPinForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * Path part for operation clientAccountManagementClientResetPassword
   */
  static readonly ClientAccountManagementClientResetPasswordPath = '/api/customer/ClientAccountManagement/ClientResetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientResetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientResetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientResetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientResetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.clientAccountManagementClientResetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientResetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientResetPassword$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientResetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientResetPassword(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.clientAccountManagementClientResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientAccountManagementCreateClient
   */
  static readonly ClientAccountManagementCreateClientPath = '/api/customer/ClientAccountManagement/CreateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementCreateClient$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementCreateClient$Plain$Response(params?: {
    context?: HttpContext
    body?: CreateClientDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementCreateClientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementCreateClient$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementCreateClient$Plain(params?: {
    context?: HttpContext
    body?: CreateClientDto
  }
): Observable<boolean> {

    return this.clientAccountManagementCreateClient$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementCreateClient()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementCreateClient$Response(params?: {
    context?: HttpContext
    body?: CreateClientDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementCreateClientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementCreateClient$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementCreateClient(params?: {
    context?: HttpContext
    body?: CreateClientDto
  }
): Observable<boolean> {

    return this.clientAccountManagementCreateClient$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientAccountManagementClientToken
   */
  static readonly ClientAccountManagementClientTokenPath = '/api/customer/ClientAccountManagement/ClientToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientToken$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientToken$Plain$Response(params?: {
    context?: HttpContext
    body?: InputClientRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputClientRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputClientRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientToken$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientToken$Plain(params?: {
    context?: HttpContext
    body?: InputClientRefreshTokenDto
  }
): Observable<OutputClientRefreshTokenDto> {

    return this.clientAccountManagementClientToken$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputClientRefreshTokenDto>) => r.body as OutputClientRefreshTokenDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientAccountManagementClientToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientToken$Response(params?: {
    context?: HttpContext
    body?: InputClientRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputClientRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientAccountManagementService.ClientAccountManagementClientTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputClientRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientAccountManagementClientToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientAccountManagementClientToken(params?: {
    context?: HttpContext
    body?: InputClientRefreshTokenDto
  }
): Observable<OutputClientRefreshTokenDto> {

    return this.clientAccountManagementClientToken$Response(params).pipe(
      map((r: StrictHttpResponse<OutputClientRefreshTokenDto>) => r.body as OutputClientRefreshTokenDto)
    );
  }

}
