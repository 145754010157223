<mat-grid-list cols="7" rowHeight="52px">
  <!-- <mat-grid-tile>
    <button type="button" matTooltip="Crop" matTooltipPosition="above" (click)="canvasCommand('START_CROP')" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">crop</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Strech Fullscreen" matTooltipPosition="above" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">fullscreen</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Strech Fullscreen" matTooltipPosition="above" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">fullscreen_exit</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Preview" matTooltipPosition="above" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">zoom_in</i></button>
  </mat-grid-tile> -->
  <mat-grid-tile>
    <button type="button" matTooltip="Clone Image" matTooltipPosition="above" (click)="canvasCommand('CLONE')" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">content_copy</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Text" matTooltipPosition="above" (click)="canvasCommand('ADD_TEXT')" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">text_fields</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Flip Horizontally" matTooltipPosition="above" (click)="canvasCommand('FLIP:X')" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">flip</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Photo Filter" matTooltipPosition="above" (click)="canvasCommand('ADD_FILTER')" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">format_paint</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Save Photo" matTooltipPosition="above" (click)="canvasCommand('DOWNLOAD_CURRENT_CANVAS')" mat-icon-button
      [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">save</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Shape Mask" matTooltipPosition="above" (click)="onChangeToolType('SHAPE_MASK')" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">filter_b_and_w</i></button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="button" matTooltip="Pen" matTooltipPosition="above" (click)="canvasCommand('PEN')" mat-icon-button [disabled]="selectedToolType==='DEACTIVATE'"><i class="material-icons" [ngClass]="{'icons':selectedToolType!=='DEACTIVATE'}">mode_edit</i></button>
  </mat-grid-tile>
</mat-grid-list>