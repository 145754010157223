/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateStylistRequestItem } from '../models/input-create-stylist-request-item';
import { OutputGetStylistRequestDto } from '../models/output-get-stylist-request-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerStylistManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation customerStylistManagementGetStylistRequest
   */
  static readonly CustomerStylistManagementGetStylistRequestPath = '/api/customer/CustomerStylistManagement/GetStylistRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerStylistManagementGetStylistRequest$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerStylistManagementGetStylistRequest$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistRequestDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerStylistManagementService.CustomerStylistManagementGetStylistRequestPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistRequestDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerStylistManagementGetStylistRequest$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerStylistManagementGetStylistRequest$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistRequestDto>> {

    return this.customerStylistManagementGetStylistRequest$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistRequestDto>>) => r.body as Array<OutputGetStylistRequestDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerStylistManagementGetStylistRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerStylistManagementGetStylistRequest$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistRequestDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerStylistManagementService.CustomerStylistManagementGetStylistRequestPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistRequestDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerStylistManagementGetStylistRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerStylistManagementGetStylistRequest(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistRequestDto>> {

    return this.customerStylistManagementGetStylistRequest$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistRequestDto>>) => r.body as Array<OutputGetStylistRequestDto>)
    );
  }

  /**
   * Path part for operation customerStylistManagementCreateStylistRequestItems
   */
  static readonly CustomerStylistManagementCreateStylistRequestItemsPath = '/api/customer/CustomerStylistManagement/CreateStylistRequestItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerStylistManagementCreateStylistRequestItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerStylistManagementCreateStylistRequestItems$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistRequestItem
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerStylistManagementService.CustomerStylistManagementCreateStylistRequestItemsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerStylistManagementCreateStylistRequestItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerStylistManagementCreateStylistRequestItems(params?: {
    context?: HttpContext
    body?: InputCreateStylistRequestItem
  }
): Observable<void> {

    return this.customerStylistManagementCreateStylistRequestItems$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
