import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { OutputGetAllItemSearchDto } from "src/app/shared/api";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-product-box-stylist",
  templateUrl: "./product-box-stylist.component.html",
  styleUrls: ["./product-box-stylist.component.scss"],
})
export class ProductBoxStylistComponent implements OnInit {
  @Input() product: OutputGetAllItemSearchDto;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = true; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  selectedColor;
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  @Output("productSelected") productSelected = new EventEmitter<any>();
  public ImageSrc: string;

  constructor(
    private productService: ProductService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000); // Skeleton Loader
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color);
      }
    }
    return uniqColor;
  }

  // Change Variants
  ChangeVariants(color, product: OutputGetAllItemSearchDto) {
    product.itemColor.map((itemColor) => {
      if (itemColor === color) {
        this.ImageSrc = color.path;
      }
    });
    this.selectedColor = color.id;
  }
  selectProduct() {
    if (this.selectedColor) {
      this.productSelected.emit({
        product: this.product,
        color: this.selectedColor,
      });
    } else {
      this.toastrService.error("please select a color ");
    }
  }
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }
  // Change Variants Image

  addToCart(product: any) {
    //this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    // this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
}
