import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable, BehaviorSubject, Subscriber } from "rxjs";
import { OutputGetBundleDetailDto } from "../api";
import { BundleCartItem } from "../modals/cart-item";

import { ToastrService } from "ngx-toastr";
// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("bundleItem")) || [];

@Injectable({
  providedIn: "root",
})
export class BundleService {
  // Array
  public cartItems: BehaviorSubject<BundleCartItem[]> = new BehaviorSubject([]);
  public observer: Subscriber<{}>;

  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {
    this.cartItems.subscribe((products) => (products = products));
  }

  // Get Products
  public getItems(): Observable<BundleCartItem[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<BundleCartItem[]>>itemsStream;
  }

  // Add to cart
  public addToCart(
    product: OutputGetBundleDetailDto,
    quantity: number,
    sizeId: number[],
    colorId: number[],
    total
  ) {
    let message, status;
    var item: BundleCartItem | boolean = false;
    // If Products exist
    let hasItem = products.find((items, index) => {
      if (items.product.id == product.id) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) {
          products[index]["quantity"] = qty;
          if (this.translate.currentLang == "ar") {
            message = "الي العربه" + product.nameAr + "تم اضافه";
          } else {
            message =
              "The product " + product.name + " has been added to cart.";
          }
          status = "success";
          this.toastrService.success(message);
        }
        return true;
      }
    });

    // If Products does not exist (Add New Products)
    if (!hasItem) {
      item = {
        product: product,
        quantity: quantity,
        sizeId: sizeId,
        colorId: colorId,
        total: total,
      };
      products.push(item);
      if (this.translate.currentLang == "ar") {
        message = "الي العربه" + product.nameAr + "تم اضافه";
      } else {
        message = "The product " + product.name + " has been added to cart.";
      }
      status = "success";
      this.toastrService.success(message);
    }

    localStorage.setItem("bundleItem", JSON.stringify(products));
    return item;
  }

  // Calculate Product stock Counts
  public calculateStockCounts(
    product: BundleCartItem,
    quantity
  ): BundleCartItem | Boolean {
    let message, status;
    let qty = product.quantity + quantity;

    for (let index = 0; index < product.product.bundleItem.length; index++) {
      const element = product.product.bundleItem[index];

      let stock = element.item.itemColor
        .filter((em) => em.id === product.colorId[index])[0]
        .size.filter((em) => em.id === product.sizeId[index])[0].quantity;
      if (stock < qty) {
        // this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');

        if (this.translate.currentLang == "ar") {
          this.toastrService.error(
            "لا يمكن اختيار عدد اكبر من المتاح في المخزن " +
              " متاح " +
              stock.toString() +
              " منتج"
          );
        } else {
          message = this.toastrService.error(
            "You can not choose more items than available. In stock " +
              stock +
              " items. "
          );
        }
        return false;
      }
    }
    return true;
  }

  // Removed in cart
  public removeFromCart(item: BundleCartItem) {
    if (item === undefined) return false;
    const index = products.indexOf(item);
    products.splice(index, 1);
    localStorage.setItem("bundleItem", JSON.stringify(products));
  }
  public removeAllFromCart() {
    products.forEach((element) => {
      this.removeFromCart(element);
    });

    if (this.translate.currentLang == "ar") {
      this.toastrService.success("تم طلب المنتجات سيتم التواصل معكم للتاكيد");
    } else {
      this.toastrService.success(
        "Products ordered. You will be contacted to confirm"
      );
    }
  }
  // Total amount
  public getTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: BundleCartItem[]) => {
        return products.reduce((prev, curr: BundleCartItem) => {
          const currentPrice = curr.total;
          return prev + currentPrice * curr.quantity;
        }, 0);
      })
    );
  }

  // Update Cart Value
  public updateCartQuantity(
    product: BundleCartItem,
    quantity: number
  ): BundleCartItem | boolean {
    return products.find((items, index) => {
      if (items.product.id == product.product.id) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) products[index]["quantity"] = qty;
        localStorage.setItem("bundleItem", JSON.stringify(products));
        return true;
      }
    });
  }
  public updateCart(
    product: BundleCartItem,
    sizeId: number[],
    colorId: number[]
  ): BundleCartItem | boolean {
    return products.find((items, index) => {
      if (items.product.id == product.product.id) {
        product.sizeId = sizeId;
        product.colorId = colorId;
        localStorage.setItem("bundleItem", JSON.stringify(products));
        return true;
      }
    });
  }
}
