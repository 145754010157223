<div class="image-picker-wrapper">
  <div class="icon-panel">
    <button mat-icon-button id='upload-file-button' (click)="onUploadButtonTrigger()"> <i class="material-icons icons">add_a_photo</i>
      <input type="file" id="upload-file-input" style="display:none;" multiple accept="image/*" (change)="onUpload($event)"/>
    </button>
    
     
    
    <button mat-icon-button (click)="onRemoveObjectFromCanvas()" *ngIf="selection!==undefined" ng color="warn"><i class="material-icons">delete_forever</i></button>
   
  </div>
  <div class="photo-slider">
    <div *ngFor="let url of fileUrlList" class="thumbnail-wrapper">
      <img [src]="url" (click)="addImageOnCanvas(url);" class="thumbnail" />
    </div>
  </div>
</div>