/* tslint:disable */
/* eslint-disable */
export enum MemberTypes {
  $1 = 1,
  $2 = 2,
  $4 = 4,
  $8 = 8,
  $16 = 16,
  $32 = 32,
  $64 = 64,
  $128 = 128,
  $191 = 191
}
