import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscriber } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Product } from "../modals/product.model";
import { OutputGetAllItemSearchDto } from "../api";
import { ToastrService } from "ngx-toastr";

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Dollar", currency: "USD", price: 1 };
  public catalogMode: boolean = false;

  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";

  public compareProducts: BehaviorSubject<OutputGetAllItemSearchDto[]> =
    new BehaviorSubject(products);
  public observer: Subscriber<{}>;

  constructor(
    private httpClient: HttpClient,

    private translate: TranslateService
  ) {
    this.compareProducts.subscribe((products) => (products = products));
  }

  private products(): Observable<OutputGetAllItemSearchDto[]> {
    return this.httpClient.get<OutputGetAllItemSearchDto[]>(
      "assets/data/products2.json"
    );
  }

  public banners(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url);
  }

  // Get Banners
  public getBanners() {
    return this.banners();
  }

  // Get Banners
  public getProducts(): Observable<OutputGetAllItemSearchDto[]> {
    return this.products();
  }

  // Get Products By Id
  public getProduct(id: number): Observable<OutputGetAllItemSearchDto> {
    return this.products().pipe(
      map((items) => {
        return items.find((item: OutputGetAllItemSearchDto) => {
          return item.id === id;
        });
      })
    );
    // return this.products.find(product=> product.id === id);

    // return this.httpClient.get<Product>(this._url + 'product-' + id + '.json');
  }

  /*
      ---------------------------------------------
      ----------  Compare Product  ----------------
      ---------------------------------------------
   */

  // Get Compare Products
  public getComapreProducts(): Observable<OutputGetAllItemSearchDto[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<OutputGetAllItemSearchDto[]>>itemsStream;
  }

  // If item is aleready added In compare
  public hasProduct(product: OutputGetAllItemSearchDto): boolean {
    const item = products.find((item) => item.id === product.id);
    return item !== undefined;
  }

  // Add to compare
  public addToCompare(product: OutputGetAllItemSearchDto): Product | boolean {
    let message, status;
    var item: Product | boolean = false;
    if (this.hasProduct(product)) {
      item = products.filter((item) => item.id === product.id)[0];
      const index = products.indexOf(item);
    } else {
      if (products.length < 4) products.push(product);
      if (this.translate.currentLang == "ar") {
        message = product.nameAr + " تم اضافته للمقارنه ";
      } else {
        message =
          "The product " + product.name + " has been added to comparison list.";
      }
      status = "success";
    }
    localStorage.setItem("compareItem", JSON.stringify(products));
    this.compareProducts.next(products);
    return item;
  }

  // Removed Product
  public removeFromCompare(product: OutputGetAllItemSearchDto) {
    if (product === undefined) {
      return;
    }
    const index = products.indexOf(product);
    products.splice(index, 1);
    localStorage.setItem("compareItem", JSON.stringify(products));
    this.compareProducts.next(products);
  }

  // Get Products By category
  public getProductByCategory(
    category: string
  ): Observable<OutputGetAllItemSearchDto[]> {
    return this.products();
  }
}
