import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CacheDataService } from "./cache-data.service";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  image?: string;
  active?: boolean;
  badge?: boolean;
  badgeText?: string;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor(private cacheDataService: CacheDataService) {
    this.LEFTMENUITEMS = cacheDataService.OutputGetSiteContent?.category.map(
      (category) => {
        return {
          title: category.name,
          type: "sub",

          active: true,
          children: category.subCategorys.map((subCategory) => {
            return {
              path: "/shop/products/all/" + subCategory.name,
              title: subCategory.name,
              type: "link",
            };
          }),
        };
      }
    );
  }

  public screenWidth: any;
  public leftMenuToggle: boolean = false;
  public mainMenuToggle: boolean = false;

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      active: false,
      type: "link",
      path: "/",
    },
    // {
    //   title: "Messages",
    //   active: false,
    //   type: "link",
    // },
    {
      title: "Collections",
      active: false,
      type: "link",
      path: "/pages/profile",
    },
    {
      title: "Analytics",
      active: false,
      type: "link",
      path: "/pages/analytics",
    },
    {
      title: "Earning",
      active: false,
      type: "link",
      path: "/pages/earning",
    },
  ];

  public LEFTMENUITEMS: Menu[] = [];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
