import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppConfigService } from "src/AppConfigService";
import {
  ApiConfiguration,
  CustomerLookupsManagementService,
  OutputGetSiteContent,
} from "../api";

@Injectable({
  providedIn: "root",
})
export class CacheDataService {
  constructor(
    private customerLookupsManagementService: CustomerLookupsManagementService,
    private apiConfiguration: ApiConfiguration
  ) {}

  public OutputGetSiteContent: OutputGetSiteContent;

  getData() {
    this.apiConfiguration.rootUrl = AppConfigService.appConfig.ApiBaseUrl;
    this.customerLookupsManagementService
      .customerLookupsManagementGetSiteContent()
      .subscribe((value) => {
        this.OutputGetSiteContent = value;
      });
  }
}
