/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputGetItemsSearchDto } from '../models/input-get-items-search-dto';
import { OutGetItemDetails } from '../models/out-get-item-details';
import { OutputGetAllBundleDto } from '../models/output-get-all-bundle-dto';
import { OutputGetAllCategoryDto } from '../models/output-get-all-category-dto';
import { OutputGetAllCollectionSearchDtoListOutputPagedResponseDto } from '../models/output-get-all-collection-search-dto-list-output-paged-response-dto';
import { OutputGetAllItemSearchDto } from '../models/output-get-all-item-search-dto';
import { OutputGetAllItemSearchDtoListOutputPagedResponseDto } from '../models/output-get-all-item-search-dto-list-output-paged-response-dto';
import { OutputGetBundleDetailDto } from '../models/output-get-bundle-detail-dto';
import { OutputGetCollectionDetailsDto } from '../models/output-get-collection-details-dto';
import { OutputGetCollectionFilterByGenderDto } from '../models/output-get-collection-filter-by-gender-dto';
import { OutputGetItemsFilterDto } from '../models/output-get-items-filter-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerItemManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation customerItemManagementGetAllCategory
   */
  static readonly CustomerItemManagementGetAllCategoryPath = '/api/customer/CustomerItemManagement/GetAllCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.customerItemManagementGetAllCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.customerItemManagementGetAllCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllCategoryByGender
   */
  static readonly CustomerItemManagementGetAllCategoryByGenderPath = '/api/customer/CustomerItemManagement/GetAllCategoryByGender';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllCategoryByGender$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategoryByGender$Plain$Response(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllCategoryByGenderPath, 'get');
    if (params) {
      rb.query('Gender', params.Gender, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllCategoryByGender$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategoryByGender$Plain(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.customerItemManagementGetAllCategoryByGender$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllCategoryByGender()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategoryByGender$Response(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllCategoryByGenderPath, 'get');
    if (params) {
      rb.query('Gender', params.Gender, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllCategoryByGender$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCategoryByGender(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.customerItemManagementGetAllCategoryByGender$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllItem
   */
  static readonly CustomerItemManagementGetAllItemPath = '/api/customer/CustomerItemManagement/GetAllItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItem$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItem$Plain$Response(params?: {
    Search?: string;
    MinPrice?: number;
    MaxPrice?: number;
    SelectedColorCategory?: Array<number>;
    SelectedColors?: Array<number>;
    SelectedSizes?: Array<number>;
    SelectedSubCategory?: Array<number>;
    SelectedGenders?: Array<string>;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {"style":"form"});
      rb.query('MinPrice', params.MinPrice, {"style":"form"});
      rb.query('MaxPrice', params.MaxPrice, {"style":"form"});
      rb.query('SelectedColorCategory', params.SelectedColorCategory, {"style":"form"});
      rb.query('SelectedColors', params.SelectedColors, {"style":"form"});
      rb.query('SelectedSizes', params.SelectedSizes, {"style":"form"});
      rb.query('SelectedSubCategory', params.SelectedSubCategory, {"style":"form"});
      rb.query('SelectedGenders', params.SelectedGenders, {"style":"form"});
      rb.query('SortingDirection', params.SortingDirection, {"style":"form"});
      rb.query('SortingExpression', params.SortingExpression, {"style":"form"});
      rb.query('PageNumber', params.PageNumber, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItem$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItem$Plain(params?: {
    Search?: string;
    MinPrice?: number;
    MaxPrice?: number;
    SelectedColorCategory?: Array<number>;
    SelectedColors?: Array<number>;
    SelectedSizes?: Array<number>;
    SelectedSubCategory?: Array<number>;
    SelectedGenders?: Array<string>;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<OutputGetAllItemSearchDtoListOutputPagedResponseDto> {

    return this.customerItemManagementGetAllItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemSearchDtoListOutputPagedResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItem$Response(params?: {
    Search?: string;
    MinPrice?: number;
    MaxPrice?: number;
    SelectedColorCategory?: Array<number>;
    SelectedColors?: Array<number>;
    SelectedSizes?: Array<number>;
    SelectedSubCategory?: Array<number>;
    SelectedGenders?: Array<string>;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {"style":"form"});
      rb.query('MinPrice', params.MinPrice, {"style":"form"});
      rb.query('MaxPrice', params.MaxPrice, {"style":"form"});
      rb.query('SelectedColorCategory', params.SelectedColorCategory, {"style":"form"});
      rb.query('SelectedColors', params.SelectedColors, {"style":"form"});
      rb.query('SelectedSizes', params.SelectedSizes, {"style":"form"});
      rb.query('SelectedSubCategory', params.SelectedSubCategory, {"style":"form"});
      rb.query('SelectedGenders', params.SelectedGenders, {"style":"form"});
      rb.query('SortingDirection', params.SortingDirection, {"style":"form"});
      rb.query('SortingExpression', params.SortingExpression, {"style":"form"});
      rb.query('PageNumber', params.PageNumber, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItem(params?: {
    Search?: string;
    MinPrice?: number;
    MaxPrice?: number;
    SelectedColorCategory?: Array<number>;
    SelectedColors?: Array<number>;
    SelectedSizes?: Array<number>;
    SelectedSubCategory?: Array<number>;
    SelectedGenders?: Array<string>;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<OutputGetAllItemSearchDtoListOutputPagedResponseDto> {

    return this.customerItemManagementGetAllItem$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemSearchDtoListOutputPagedResponseDto)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllItemByName
   */
  static readonly CustomerItemManagementGetAllItemByNamePath = '/api/customer/CustomerItemManagement/GetAllItemByName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemByName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemByName$Plain$Response(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemSearchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemByNamePath, 'post');
    if (params) {
      rb.query('Name', params.Name, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemSearchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemByName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemByName$Plain(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemSearchDto>> {

    return this.customerItemManagementGetAllItemByName$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemSearchDto>>) => r.body as Array<OutputGetAllItemSearchDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemByName$Response(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemSearchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemByNamePath, 'post');
    if (params) {
      rb.query('Name', params.Name, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemSearchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemByName(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemSearchDto>> {

    return this.customerItemManagementGetAllItemByName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemSearchDto>>) => r.body as Array<OutputGetAllItemSearchDto>)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllItemsByCategoryId
   */
  static readonly CustomerItemManagementGetAllItemsByCategoryIdPath = '/api/customer/CustomerItemManagement/GetAllItemsByCategoryId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemsByCategoryId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsByCategoryId$Plain$Response(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemSearchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemsByCategoryIdPath, 'post');
    if (params) {
      rb.query('CategoryId', params.CategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemSearchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemsByCategoryId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsByCategoryId$Plain(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemSearchDto>> {

    return this.customerItemManagementGetAllItemsByCategoryId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemSearchDto>>) => r.body as Array<OutputGetAllItemSearchDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemsByCategoryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsByCategoryId$Response(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemSearchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemsByCategoryIdPath, 'post');
    if (params) {
      rb.query('CategoryId', params.CategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemSearchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemsByCategoryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsByCategoryId(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemSearchDto>> {

    return this.customerItemManagementGetAllItemsByCategoryId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemSearchDto>>) => r.body as Array<OutputGetAllItemSearchDto>)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllItemsBySubCategoryId
   */
  static readonly CustomerItemManagementGetAllItemsBySubCategoryIdPath = '/api/customer/CustomerItemManagement/GetAllItemsBySubCategoryId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemsBySubCategoryId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsBySubCategoryId$Plain$Response(params?: {
    SubCategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemSearchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemsBySubCategoryIdPath, 'post');
    if (params) {
      rb.query('SubCategoryId', params.SubCategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemSearchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemsBySubCategoryId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsBySubCategoryId$Plain(params?: {
    SubCategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemSearchDto>> {

    return this.customerItemManagementGetAllItemsBySubCategoryId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemSearchDto>>) => r.body as Array<OutputGetAllItemSearchDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemsBySubCategoryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsBySubCategoryId$Response(params?: {
    SubCategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemSearchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemsBySubCategoryIdPath, 'post');
    if (params) {
      rb.query('SubCategoryId', params.SubCategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemSearchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemsBySubCategoryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllItemsBySubCategoryId(params?: {
    SubCategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemSearchDto>> {

    return this.customerItemManagementGetAllItemsBySubCategoryId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemSearchDto>>) => r.body as Array<OutputGetAllItemSearchDto>)
    );
  }

  /**
   * Path part for operation customerItemManagementGetItemDetails
   */
  static readonly CustomerItemManagementGetItemDetailsPath = '/api/customer/CustomerItemManagement/GetItemDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetItemDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemDetails$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutGetItemDetails>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetItemDetailsPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutGetItemDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetItemDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemDetails$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutGetItemDetails> {

    return this.customerItemManagementGetItemDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutGetItemDetails>) => r.body as OutGetItemDetails)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetItemDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemDetails$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutGetItemDetails>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetItemDetailsPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutGetItemDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetItemDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemDetails(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutGetItemDetails> {

    return this.customerItemManagementGetItemDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutGetItemDetails>) => r.body as OutGetItemDetails)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllItemsSlider
   */
  static readonly CustomerItemManagementGetAllItemsSliderPath = '/api/customer/CustomerItemManagement/GetAllItemsSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemsSlider$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerItemManagementGetAllItemsSlider$Plain$Response(params?: {
    SliderId?: number;
    context?: HttpContext
    body?: InputGetItemsSearchDto
  }
): Observable<StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemsSliderPath, 'post');
    if (params) {
      rb.query('SliderId', params.SliderId, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemsSlider$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerItemManagementGetAllItemsSlider$Plain(params?: {
    SliderId?: number;
    context?: HttpContext
    body?: InputGetItemsSearchDto
  }
): Observable<OutputGetAllItemSearchDtoListOutputPagedResponseDto> {

    return this.customerItemManagementGetAllItemsSlider$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemSearchDtoListOutputPagedResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllItemsSlider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerItemManagementGetAllItemsSlider$Response(params?: {
    SliderId?: number;
    context?: HttpContext
    body?: InputGetItemsSearchDto
  }
): Observable<StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllItemsSliderPath, 'post');
    if (params) {
      rb.query('SliderId', params.SliderId, {"style":"form"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllItemsSlider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerItemManagementGetAllItemsSlider(params?: {
    SliderId?: number;
    context?: HttpContext
    body?: InputGetItemsSearchDto
  }
): Observable<OutputGetAllItemSearchDtoListOutputPagedResponseDto> {

    return this.customerItemManagementGetAllItemsSlider$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemSearchDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemSearchDtoListOutputPagedResponseDto)
    );
  }

  /**
   * Path part for operation customerItemManagementGetItemsFilter
   */
  static readonly CustomerItemManagementGetItemsFilterPath = '/api/customer/CustomerItemManagement/GetItemsFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetItemsFilter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemsFilter$Plain$Response(params?: {
    SubCategory?: Array<number>;
    Gender?: Array<string>;
    Search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetItemsFilterDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetItemsFilterPath, 'get');
    if (params) {
      rb.query('SubCategory', params.SubCategory, {"style":"form"});
      rb.query('Gender', params.Gender, {"style":"form"});
      rb.query('Search', params.Search, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetItemsFilterDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetItemsFilter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemsFilter$Plain(params?: {
    SubCategory?: Array<number>;
    Gender?: Array<string>;
    Search?: string;
    context?: HttpContext
  }
): Observable<OutputGetItemsFilterDto> {

    return this.customerItemManagementGetItemsFilter$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetItemsFilterDto>) => r.body as OutputGetItemsFilterDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetItemsFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemsFilter$Response(params?: {
    SubCategory?: Array<number>;
    Gender?: Array<string>;
    Search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetItemsFilterDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetItemsFilterPath, 'get');
    if (params) {
      rb.query('SubCategory', params.SubCategory, {"style":"form"});
      rb.query('Gender', params.Gender, {"style":"form"});
      rb.query('Search', params.Search, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetItemsFilterDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetItemsFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetItemsFilter(params?: {
    SubCategory?: Array<number>;
    Gender?: Array<string>;
    Search?: string;
    context?: HttpContext
  }
): Observable<OutputGetItemsFilterDto> {

    return this.customerItemManagementGetItemsFilter$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetItemsFilterDto>) => r.body as OutputGetItemsFilterDto)
    );
  }

  /**
   * Path part for operation customerItemManagementGetBundleDetails
   */
  static readonly CustomerItemManagementGetBundleDetailsPath = '/api/customer/CustomerItemManagement/GetBundleDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetBundleDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetBundleDetails$Plain$Response(params?: {
    bundleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBundleDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetBundleDetailsPath, 'get');
    if (params) {
      rb.query('bundleId', params.bundleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBundleDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetBundleDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetBundleDetails$Plain(params?: {
    bundleId?: number;
    context?: HttpContext
  }
): Observable<OutputGetBundleDetailDto> {

    return this.customerItemManagementGetBundleDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBundleDetailDto>) => r.body as OutputGetBundleDetailDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetBundleDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetBundleDetails$Response(params?: {
    bundleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBundleDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetBundleDetailsPath, 'get');
    if (params) {
      rb.query('bundleId', params.bundleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBundleDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetBundleDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetBundleDetails(params?: {
    bundleId?: number;
    context?: HttpContext
  }
): Observable<OutputGetBundleDetailDto> {

    return this.customerItemManagementGetBundleDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBundleDetailDto>) => r.body as OutputGetBundleDetailDto)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllBundles
   */
  static readonly CustomerItemManagementGetAllBundlesPath = '/api/customer/CustomerItemManagement/GetAllBundles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllBundles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllBundles$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBundleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllBundlesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBundleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllBundles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllBundles$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBundleDto>> {

    return this.customerItemManagementGetAllBundles$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBundleDto>>) => r.body as Array<OutputGetAllBundleDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllBundles()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllBundles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBundleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllBundlesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBundleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllBundles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllBundles(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBundleDto>> {

    return this.customerItemManagementGetAllBundles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBundleDto>>) => r.body as Array<OutputGetAllBundleDto>)
    );
  }

  /**
   * Path part for operation customerItemManagementGetAllCollection
   */
  static readonly CustomerItemManagementGetAllCollectionPath = '/api/customer/CustomerItemManagement/GetAllCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllCollection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCollection$Plain$Response(params?: {
    SelectedGender?: Array<string>;
    SelectedStyle?: Array<number>;
    MinPrice?: number;
    MaxPrice?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllCollectionPath, 'get');
    if (params) {
      rb.query('SelectedGender', params.SelectedGender, {"style":"form"});
      rb.query('SelectedStyle', params.SelectedStyle, {"style":"form"});
      rb.query('MinPrice', params.MinPrice, {"style":"form"});
      rb.query('MaxPrice', params.MaxPrice, {"style":"form"});
      rb.query('SortingDirection', params.SortingDirection, {"style":"form"});
      rb.query('SortingExpression', params.SortingExpression, {"style":"form"});
      rb.query('PageNumber', params.PageNumber, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllCollection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCollection$Plain(params?: {
    SelectedGender?: Array<string>;
    SelectedStyle?: Array<number>;
    MinPrice?: number;
    MaxPrice?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto> {

    return this.customerItemManagementGetAllCollection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto>) => r.body as OutputGetAllCollectionSearchDtoListOutputPagedResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetAllCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCollection$Response(params?: {
    SelectedGender?: Array<string>;
    SelectedStyle?: Array<number>;
    MinPrice?: number;
    MaxPrice?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetAllCollectionPath, 'get');
    if (params) {
      rb.query('SelectedGender', params.SelectedGender, {"style":"form"});
      rb.query('SelectedStyle', params.SelectedStyle, {"style":"form"});
      rb.query('MinPrice', params.MinPrice, {"style":"form"});
      rb.query('MaxPrice', params.MaxPrice, {"style":"form"});
      rb.query('SortingDirection', params.SortingDirection, {"style":"form"});
      rb.query('SortingExpression', params.SortingExpression, {"style":"form"});
      rb.query('PageNumber', params.PageNumber, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetAllCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetAllCollection(params?: {
    SelectedGender?: Array<string>;
    SelectedStyle?: Array<number>;
    MinPrice?: number;
    MaxPrice?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto> {

    return this.customerItemManagementGetAllCollection$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllCollectionSearchDtoListOutputPagedResponseDto>) => r.body as OutputGetAllCollectionSearchDtoListOutputPagedResponseDto)
    );
  }

  /**
   * Path part for operation customerItemManagementGetCollectionsFilter
   */
  static readonly CustomerItemManagementGetCollectionsFilterPath = '/api/customer/CustomerItemManagement/GetCollectionsFilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetCollectionsFilter$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionsFilter$Plain$Response(params?: {
    gender?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetCollectionFilterByGenderDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetCollectionsFilterPath, 'get');
    if (params) {
      rb.query('gender', params.gender, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetCollectionFilterByGenderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetCollectionsFilter$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionsFilter$Plain(params?: {
    gender?: Array<string>;
    context?: HttpContext
  }
): Observable<OutputGetCollectionFilterByGenderDto> {

    return this.customerItemManagementGetCollectionsFilter$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetCollectionFilterByGenderDto>) => r.body as OutputGetCollectionFilterByGenderDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetCollectionsFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionsFilter$Response(params?: {
    gender?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetCollectionFilterByGenderDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetCollectionsFilterPath, 'get');
    if (params) {
      rb.query('gender', params.gender, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetCollectionFilterByGenderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetCollectionsFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionsFilter(params?: {
    gender?: Array<string>;
    context?: HttpContext
  }
): Observable<OutputGetCollectionFilterByGenderDto> {

    return this.customerItemManagementGetCollectionsFilter$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetCollectionFilterByGenderDto>) => r.body as OutputGetCollectionFilterByGenderDto)
    );
  }

  /**
   * Path part for operation customerItemManagementGetCollectionDetailsById
   */
  static readonly CustomerItemManagementGetCollectionDetailsByIdPath = '/api/customer/CustomerItemManagement/GetCollectionDetailsById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetCollectionDetailsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionDetailsById$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetCollectionDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetCollectionDetailsByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetCollectionDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetCollectionDetailsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionDetailsById$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutputGetCollectionDetailsDto> {

    return this.customerItemManagementGetCollectionDetailsById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetCollectionDetailsDto>) => r.body as OutputGetCollectionDetailsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerItemManagementGetCollectionDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionDetailsById$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetCollectionDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerItemManagementService.CustomerItemManagementGetCollectionDetailsByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetCollectionDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerItemManagementGetCollectionDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerItemManagementGetCollectionDetailsById(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutputGetCollectionDetailsDto> {

    return this.customerItemManagementGetCollectionDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetCollectionDetailsDto>) => r.body as OutputGetCollectionDetailsDto)
    );
  }

}
