/* tslint:disable */
/* eslint-disable */
export enum PropertyAttributes {
  $0 = 0,
  $512 = 512,
  $1024 = 1024,
  $4096 = 4096,
  $8192 = 8192,
  $16384 = 16384,
  $32768 = 32768,
  $62464 = 62464
}
