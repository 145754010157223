/* tslint:disable */
/* eslint-disable */
export enum TypeAttributes {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
  $16 = 16,
  $24 = 24,
  $32 = 32,
  $128 = 128,
  $256 = 256,
  $1024 = 1024,
  $2048 = 2048,
  $4096 = 4096,
  $8192 = 8192,
  $16384 = 16384,
  $65536 = 65536,
  $131072 = 131072,
  $196608 = 196608,
  $262144 = 262144,
  $264192 = 264192,
  $1048576 = 1048576,
  $12582912 = 12582912
}
