/* tslint:disable */
/* eslint-disable */
export enum FieldAttributes {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $16 = 16,
  $32 = 32,
  $64 = 64,
  $128 = 128,
  $256 = 256,
  $512 = 512,
  $1024 = 1024,
  $4096 = 4096,
  $8192 = 8192,
  $32768 = 32768,
  $38144 = 38144
}
