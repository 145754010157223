 
<div class="picker-container" style="margin:12px 0px 0px 0px;">
  <input [cpOutputFormat] ="'rgba'"      (colorPickerChange)="changeConfig()" style="    visibility: hidden;
  width: 0px;"   [(colorPicker)]="color" #colorPick  >
  <button type="button" matTooltip="Color Picker" matTooltipPosition="above"   mat-icon-button  type="button" (click)="colorPick.click()"
     >
  <i [style.color]="color " class="material-icons icons">color_lens</i>
  </button>
</div>

 
 
<div class="picker-container">
  <div matTooltip="Font Size" matTooltipPosition="above" class="font-size-text">
    {{fontSize}}
  </div>
  <div class="slider-wrapper">
    <mat-slider   min="1" step="1" max="100" thumb-label="true" [(ngModel)]="fontSize" (change)="changeConfig()"
      class="slider "></mat-slider>
  </div>
</div>  