import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/AuthService"; 

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() class: string;
  @Input() themeLogo: string = "assets/img/logo.png"; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  active =false
  public stick: boolean = false;

  constructor(
    private router: Router,
    public authService: AuthService, 
  ) {}

  ngOnInit(): void {}

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
  changeGender(name) {
    this.router.navigate(["shop/products/" + name + "/All"]);
  }
  isSelectedGender(name) {
    return this.router.url.includes("/" + name + "/");
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenStylist");
    localStorage.removeItem("refreshToken");
  }
}
