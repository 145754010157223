import { Component, OnInit, Input } from "@angular/core";
import { NewProductSlider } from "../../../data/slider";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { CacheDataService } from "src/app/shared/services/cache-data.service";
import { OutputGetAllItemSearchDto } from "src/app/shared/api";

@Component({
  selector: "app-product-box-vertical-slider",
  templateUrl: "./product-box-vertical-slider.component.html",
  styleUrls: ["./product-box-vertical-slider.component.scss"],
})
export class ProductBoxVerticalSliderComponent implements OnInit {
  @Input() title: string = "New Product"; // Default
  @Input() type: string = "fashion"; // Default Fashion

  public products: OutputGetAllItemSearchDto[];

  public NewProductSliderConfig: any = NewProductSlider;
  currency: any = this.productService.Currency;
  constructor(
    public cacheDataService: CacheDataService,
    private productService: ProductService
  ) {
    this.products = this.cacheDataService.OutputGetSiteContent.newProducts;
  }

  ngOnInit(): void {}
}
