/* tslint:disable */
/* eslint-disable */
export enum MethodImplAttributes {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $8 = 8,
  $16 = 16,
  $32 = 32,
  $64 = 64,
  $128 = 128,
  $256 = 256,
  $512 = 512,
  $4096 = 4096,
  $65535 = 65535
}
