import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable, BehaviorSubject, Subscriber } from "rxjs";
import { CartItem } from "../modals/cart-item";
import { OutGetItemDetails, OutputGetAllItemSearchDto } from "../api";
import { ToastrService } from "ngx-toastr";

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("cartItem")) || [];

@Injectable({
  providedIn: "root",
})
export class CartService {
  // Array
  public cartItems: BehaviorSubject<CartItem[]> = new BehaviorSubject(products);
  public observer: Subscriber<{}>;
  public OpenCart;
  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}

  // Get Products
  public getItems(): Observable<CartItem[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<CartItem[]>>itemsStream;
  }

  public addItemCollectionToCart(
    product: OutGetItemDetails,
    quantity: number,
    sizeId: number,
    colorId: number,
    price: number,
    collectionId: number
  ) {
    let message, status;
    var item: CartItem | boolean = false;
    // If Products exist
    let hasItem = products.find((items, index) => {
     
      if (
        items.product.id == product.id &&
        items.sizeId == sizeId &&
        items.colorId == colorId
      ) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) {
          products[index]["quantity"] = qty;
        }
        return true;
      }
    });

    // If Products does not exist (Add New Products)
    if (!hasItem) {
      item = {
        product: product,
        quantity: quantity,
        sizeId: sizeId,
        colorId: colorId,
        price: price,
        collectionId: collectionId,
        isCollection: true,
      };
      products.push(item);
    }

    localStorage.setItem("cartItem", JSON.stringify(products));
    this.cartItems.next(products);
    return item;
  }

  // Add to cart
  public addToCart(
    product: OutGetItemDetails,
    quantity: number,
    sizeId: number,
    colorId: number,
    price: number
  ) {
    let message, status;
    var item: CartItem | boolean = false;
    // If Products exist
    let hasItem = products.find((items, index) => {
     
      if (
        items.product.id == product.id &&
        items.sizeId == sizeId &&
        items.colorId == colorId
      ) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) {
          products[index]["quantity"] = qty;
          if (this.translate.currentLang == "ar") {
            message = "الي العربه" + product.nameAr + "تم اضافه";
          } else {
            message =
              "The product " + product.name + " has been added to cart.";
          }
          status = "success";
          this.toastrService.success(message);
        }
        return true;
      }
    });

    // If Products does not exist (Add New Products)
    if (!hasItem) {
      item = {
        product: product,
        quantity: quantity,
        sizeId: sizeId,
        colorId: colorId,
        price: price,
      };
      products.push(item);
      if (this.translate.currentLang == "ar") {
        message = "الي العربه" + product.nameAr + "تم اضافه";
      } else {
        message = "The product " + product.name + " has been added to cart.";
      }
      status = "success";
      this.toastrService.success(message);
    }

    localStorage.setItem("cartItem", JSON.stringify(products));
    this.cartItems.next(products);
    return item;
  }

  public addCollectionToCart(
    product: OutGetItemDetails,
    quantity: number,
    sizeId: number,
    colorId: number,
    price: number
  ) {
    let message, status;
    var item: CartItem | boolean = false;
    // If Products exist
    let hasItem = products.find((items, index) => {
     
      if (
        items.product.id == product.id &&
        items.sizeId == sizeId &&
        items.colorId == colorId
      ) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) {
          products[index]["quantity"] = qty;
        }
        return true;
      }
    });

    // If Products does not exist (Add New Products)
    if (!hasItem) {
      item = {
        product: product,
        quantity: quantity,
        sizeId: sizeId,
        colorId: colorId,
        price: price,
      };
      products.push(item);
      if (this.translate.currentLang == "ar") {
        message = "الي العربه" + product.nameAr + "تم اضافه";
      } else {
        message = "The product " + product.name + " has been added to cart.";
      }
      status = "success";
      this.toastrService.success(message);
    }

    localStorage.setItem("cartItem", JSON.stringify(products));
    this.cartItems.next(products);
    return item;
  }
  // Calculate Product stock Counts
  public calculateStockCounts(product: CartItem, quantity): CartItem | Boolean {
    let message, status;
    let qty = product.quantity + quantity;

    let stock = product.product.itemColor
      .filter((em) => em.id === product.colorId)[0]
      .size.filter((em) => em.id === product.sizeId)[0].quantity;
    if (stock < qty) {
      // this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');

      if (this.translate.currentLang == "ar") {
        this.toastrService.success(
          "لا يمكن اختيار عدد اكبر من المتاح في المخزن " +
            " متاح " +
            stock.toString() +
            " منتج"
        );
      } else {
        this.toastrService.success(
          "You can not choose more items than available. In stock " +
            stock +
            " items. "
        );
      }
      return false;
    }
    return true;
  }

  // Removed in cart
  public removeFromCart(item: CartItem) {
    if (item === undefined) return false;
    const index = products.indexOf(item);
    products.splice(index, 1);
    localStorage.setItem("cartItem", JSON.stringify(products));
    this.cartItems.next(products);
  }
  public removeAllFromCart() {
    products.forEach((element) => {
      this.removeFromCart(element);
    });

    if (this.translate.currentLang == "ar") {
      this.toastrService.success(
        "تم طلب المنتجات سيتم التواصل معكم للتاكيد",
        null
      );
    } else {
      this.toastrService.success(
        "Products ordered. You will be contacted to confirm"
      );
    }
  }
  // Total amount
  public getTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: CartItem[]) => {
        return products.reduce((prev, curr: CartItem) => {
          const currentPrice =
            //curr.product.offers
            //  ? curr.product.price - (curr.product.price * curr.product.offers.percentage) / 100
            //  :
            curr.price;
          return prev + currentPrice * curr.quantity;
        }, 0);
      })
    );
  }

  // Update Cart Value
  public updateCartQuantity(
    product: CartItem,
    quantity: number
  ): CartItem | boolean {
    return products.find((items, index) => {
      if (items.product.id == product.product.id) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) products[index]["quantity"] = qty;
        localStorage.setItem("cartItem", JSON.stringify(products));
        this.cartItems.next(products);
        return true;
      }
    });
  }
  public updateCart(
    product: CartItem,
    sizeId: number,
    colorId: number,
    price: number
  ): CartItem | boolean {
    return products.find((items, index) => {
      if (items.product.id == product.product.id) {
        product.sizeId = sizeId;
        product.colorId = colorId;
        product.price = price;
        products[index] = product;
        localStorage.setItem("cartItem", JSON.stringify(products));
        this.cartItems.next(products);
        return true;
      }
    });
  }
  public removeDuplication() {
    var editProduct = JSON.parse(localStorage.getItem("cartItem")) || [];
    (editProduct as CartItem[]).forEach((items, index) => {
     
      if (
        editProduct.filter((em) => em.product.id == items.product.id).length > 1
      ) {
        //  this.removeFromCart(items);

        editProduct[
          editProduct.indexOf(
            editProduct.filter((em) => em.product.id == items.product.id)[0]
          )
        ].quantity =
          editProduct.filter((em) => em.product.id == items.product.id)[0]
            .quantity +
          editProduct.filter((em) => em.product.id == items.product.id)[1]
            .quantity;

        editProduct.splice(
          editProduct.indexOf(
            editProduct.filter((em) => em.product.id == items.product.id)[1]
          ),
          1
        );

        localStorage.setItem("cartItem", JSON.stringify(editProduct));
        this.cartItems.next(products);
      }
    });
  }
}
