import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule, scrollPreset } from "ng-lazyload-image";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TranslateModule } from "@ngx-translate/core";

// Header and Footer Components
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";

// Components
import { LeftMenuComponent } from "./components/left-menu/left-menu.component";
import { MenuComponent } from "./components/menu/menu.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { CategoriesComponent } from "./components/categories/categories.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ProductBoxOneComponent } from "./components/product/product-box-one/product-box-one.component";
import { ProductBoxTwoComponent } from "./components/product/product-box-two/product-box-two.component";
import { ProductBoxThreeComponent } from "./components/product/product-box-three/product-box-three.component";
import { ProductBoxFourComponent } from "./components/product/product-box-four/product-box-four.component";
import { ProductBoxFiveComponent } from "./components/product/product-box-five/product-box-five.component";
import { ProductBoxVerticalComponent } from "./components/product/product-box-vertical/product-box-vertical.component";
import { ProductBoxVerticalSliderComponent } from "./components/product/product-box-vertical-slider/product-box-vertical-slider.component";

// Modals Components
import { NewsletterComponent } from "./components/modal/newsletter/newsletter.component";
import { QuickViewComponent } from "./components/modal/quick-view/quick-view.component";
import { CartModalComponent } from "./components/modal/cart-modal/cart-modal.component";
import { CartVariationComponent } from "./components/modal/cart-variation/cart-variation.component";
import { VideoModalComponent } from "./components/modal/video-modal/video-modal.component";
import { SizeModalComponent } from "./components/modal/size-modal/size-modal.component";
import { AgeVerificationComponent } from "./components/modal/age-verification/age-verification.component";

// Skeleton Loader Components
import { SkeletonProductBoxComponent } from "./components/skeleton/skeleton-product-box/skeleton-product-box.component";

// Layout Box
import { LayoutBoxComponent } from "./components/layout-box/layout-box.component";

// Tap To Top
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";

// Pipes
import { DiscountPipe } from "./pipes/discount.pipe";
import { ProductService } from "./services/product.service";
import { CartService } from "./services/cart.service";
import { BundleService } from "./services/bundle.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "./interceptors/loader.interceptor";
import { AuthService } from "./services/AuthService";
import { CacheDataService } from "./services/cache-data.service";
import { CollectionBoxOneComponent } from "./components/collections/collection-box-one/collection-box-one.component";
import { CollectionQuickViewComponent } from "./components/modal/collection-quick-view/collection-quick-view.component";
import { InputComponent } from "./components/input/input.component";
import { DroupDownComponent } from "./components/droup-down/droup-down.component";
import { RatingComponent } from "./components/rating/rating.component";
import { WishlistService } from "./services/wishlist.service";
import { ImageEditorModule } from "./image-editor/image-editor.module";
import { AuthGuardService } from "./services/AuthGuardService";
import { ProductBoxStylistComponent } from "./components/product/product-box-stylist/product-box-stylist.component";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    CollectionBoxOneComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CollectionQuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    InputComponent,
    DroupDownComponent,
    RatingComponent,

    ProductBoxStylistComponent,
  ],
  imports: [
    CommonModule,
    ImageEditorModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule.forRoot({
      // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    }),
    NgxSkeletonLoaderModule,
    TranslateModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    CollectionBoxOneComponent,
    NewsletterComponent,
    QuickViewComponent,
    CollectionQuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    InputComponent,
    DroupDownComponent,
    RatingComponent,
    ProductBoxStylistComponent,
    ImageEditorModule,
    TranslateModule,
    ToastrModule,
  ],
  providers: [
    ProductService,
    CartService,
    BundleService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },

    AuthService,
    WishlistService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: CacheDataService) => () => ds.getData(),
      deps: [CacheDataService],
      multi: true,
    },
    AuthGuardService,
  ],
})
export class SharedModule {}
