/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputAddCommentDto } from '../models/input-add-comment-dto';
import { InputClientChangeAddress } from '../models/input-client-change-address';
import { InputCreateClientAddress } from '../models/input-create-client-address';
import { OutputClientUpdateDto } from '../models/output-client-update-dto';
import { OutputGetClientAddressDto } from '../models/output-get-client-address-dto';
import { OutputGetPersonHistoryDto } from '../models/output-get-person-history-dto';
import { UpdateClientDto } from '../models/update-client-dto';

@Injectable({
  providedIn: 'root',
})
export class ClientProfileManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientProfileManagementGetClientAddress
   */
  static readonly ClientProfileManagementGetClientAddressPath = '/api/customer/ClientProfileManagement/GetClientAddress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementGetClientAddress$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetClientAddress$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetClientAddressDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementGetClientAddressPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetClientAddressDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementGetClientAddress$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetClientAddress$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetClientAddressDto>> {

    return this.clientProfileManagementGetClientAddress$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetClientAddressDto>>) => r.body as Array<OutputGetClientAddressDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementGetClientAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetClientAddress$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetClientAddressDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementGetClientAddressPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetClientAddressDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementGetClientAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetClientAddress(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetClientAddressDto>> {

    return this.clientProfileManagementGetClientAddress$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetClientAddressDto>>) => r.body as Array<OutputGetClientAddressDto>)
    );
  }

  /**
   * Path part for operation clientProfileManagementAddClientAddress
   */
  static readonly ClientProfileManagementAddClientAddressPath = '/api/customer/ClientProfileManagement/AddClientAddress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementAddClientAddress$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddClientAddress$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateClientAddress
  }
): Observable<StrictHttpResponse<OutputGetClientAddressDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementAddClientAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetClientAddressDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementAddClientAddress$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddClientAddress$Plain(params?: {
    context?: HttpContext
    body?: InputCreateClientAddress
  }
): Observable<OutputGetClientAddressDto> {

    return this.clientProfileManagementAddClientAddress$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetClientAddressDto>) => r.body as OutputGetClientAddressDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementAddClientAddress()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddClientAddress$Response(params?: {
    context?: HttpContext
    body?: InputCreateClientAddress
  }
): Observable<StrictHttpResponse<OutputGetClientAddressDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementAddClientAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetClientAddressDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementAddClientAddress$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddClientAddress(params?: {
    context?: HttpContext
    body?: InputCreateClientAddress
  }
): Observable<OutputGetClientAddressDto> {

    return this.clientProfileManagementAddClientAddress$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetClientAddressDto>) => r.body as OutputGetClientAddressDto)
    );
  }

  /**
   * Path part for operation clientProfileManagementAddBlogComment
   */
  static readonly ClientProfileManagementAddBlogCommentPath = '/api/customer/ClientProfileManagement/AddBlogComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementAddBlogComment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddBlogComment$Plain$Response(params?: {
    context?: HttpContext
    body?: InputAddCommentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementAddBlogCommentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementAddBlogComment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddBlogComment$Plain(params?: {
    context?: HttpContext
    body?: InputAddCommentDto
  }
): Observable<boolean> {

    return this.clientProfileManagementAddBlogComment$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementAddBlogComment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddBlogComment$Response(params?: {
    context?: HttpContext
    body?: InputAddCommentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementAddBlogCommentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementAddBlogComment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementAddBlogComment(params?: {
    context?: HttpContext
    body?: InputAddCommentDto
  }
): Observable<boolean> {

    return this.clientProfileManagementAddBlogComment$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientProfileManagementUpdateClientAddress
   */
  static readonly ClientProfileManagementUpdateClientAddressPath = '/api/customer/ClientProfileManagement/UpdateClientAddress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementUpdateClientAddress$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateClientAddress$Plain$Response(params?: {
    context?: HttpContext
    body?: InputClientChangeAddress
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementUpdateClientAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementUpdateClientAddress$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateClientAddress$Plain(params?: {
    context?: HttpContext
    body?: InputClientChangeAddress
  }
): Observable<boolean> {

    return this.clientProfileManagementUpdateClientAddress$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementUpdateClientAddress()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateClientAddress$Response(params?: {
    context?: HttpContext
    body?: InputClientChangeAddress
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementUpdateClientAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementUpdateClientAddress$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateClientAddress(params?: {
    context?: HttpContext
    body?: InputClientChangeAddress
  }
): Observable<boolean> {

    return this.clientProfileManagementUpdateClientAddress$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientProfileManagementGetCurrentClient
   */
  static readonly ClientProfileManagementGetCurrentClientPath = '/api/customer/ClientProfileManagement/GetCurrentClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementGetCurrentClient$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetCurrentClient$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputClientUpdateDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementGetCurrentClientPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputClientUpdateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementGetCurrentClient$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetCurrentClient$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputClientUpdateDto> {

    return this.clientProfileManagementGetCurrentClient$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputClientUpdateDto>) => r.body as OutputClientUpdateDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementGetCurrentClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetCurrentClient$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputClientUpdateDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementGetCurrentClientPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputClientUpdateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementGetCurrentClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetCurrentClient(params?: {
    context?: HttpContext
  }
): Observable<OutputClientUpdateDto> {

    return this.clientProfileManagementGetCurrentClient$Response(params).pipe(
      map((r: StrictHttpResponse<OutputClientUpdateDto>) => r.body as OutputClientUpdateDto)
    );
  }

  /**
   * Path part for operation clientProfileManagementUpdateCurrentClient
   */
  static readonly ClientProfileManagementUpdateCurrentClientPath = '/api/customer/ClientProfileManagement/UpdateCurrentClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementUpdateCurrentClient$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateCurrentClient$Plain$Response(params?: {
    context?: HttpContext
    body?: UpdateClientDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementUpdateCurrentClientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementUpdateCurrentClient$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateCurrentClient$Plain(params?: {
    context?: HttpContext
    body?: UpdateClientDto
  }
): Observable<boolean> {

    return this.clientProfileManagementUpdateCurrentClient$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementUpdateCurrentClient()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateCurrentClient$Response(params?: {
    context?: HttpContext
    body?: UpdateClientDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementUpdateCurrentClientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementUpdateCurrentClient$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientProfileManagementUpdateCurrentClient(params?: {
    context?: HttpContext
    body?: UpdateClientDto
  }
): Observable<boolean> {

    return this.clientProfileManagementUpdateCurrentClient$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientProfileManagementGetPersonHistory
   */
  static readonly ClientProfileManagementGetPersonHistoryPath = '/api/customer/ClientProfileManagement/GetPersonHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementGetPersonHistory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetPersonHistory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetPersonHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementGetPersonHistoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetPersonHistoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementGetPersonHistory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetPersonHistory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetPersonHistoryDto>> {

    return this.clientProfileManagementGetPersonHistory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetPersonHistoryDto>>) => r.body as Array<OutputGetPersonHistoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientProfileManagementGetPersonHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetPersonHistory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetPersonHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientProfileManagementService.ClientProfileManagementGetPersonHistoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetPersonHistoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientProfileManagementGetPersonHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientProfileManagementGetPersonHistory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetPersonHistoryDto>> {

    return this.clientProfileManagementGetPersonHistory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetPersonHistoryDto>>) => r.body as Array<OutputGetPersonHistoryDto>)
    );
  }

}
