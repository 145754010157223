import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  ViewChild,
  ElementRef,
  OnInit,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from "@angular/animations";
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  DefaultValueAccessor,
  AbstractControl,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true,
    },
  ],
  animations: [
    trigger("visibilityChanged", [
      state("true", style({ height: "*", "padding-top": "0px" })),
      state("false", style({ height: "0px", "padding-top": "0px" })),
      transition("*=>*", animate("200ms")),
    ]),
  ],
})
export class InputComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnInit
{
  @Input() innerValue = "";
  @Input() showText = true;

  @Output() valueChanged = new EventEmitter<any>();
  @Input() haveHide = false;
  hide = true;
  @Input() type = "text";
  @Input() hint = "";
  @Input() idd = "";
  @Input() IsDisabled = false;
  @Input() text = "";
  @Input() height = "53px";
  @Input() pH: string;
  @Input() control: FormControl | AbstractControl = new FormControl();
  @Input() optional = false;
  isRequired;
  @Input() IsDateTime = false;
  errors: Array<any> = [];
  @Input() removeLabel = false;
  @Input() isView = false;
  showPassword = false;
  @ViewChild("input") inputRef: ElementRef;

  constructor(private translate: TranslateService) {
    this.value = this.innerValue;
    translate.onLangChange.subscribe((val) => {
      try {
        if (this.control.touched) {
          this.onChange(null, this.value);
        }
      } catch (e) {}
    });
  }

  ngOnInit(): void {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }

  ngOnChanges(...args: any[]) {
    try {
      this.value = args[0].c.currentValue.value;

      if (this.value !== "") {
        this.onChange(null, this.value);
      }
    } catch (e) {}
  }

  ngAfterViewInit() {
    this.control.valueChanges.subscribe((value) => {
      this.control.markAsTouched();
      this.innerValue = value;

      this.errors = [];
      for (const key in this.control.errors) {
        if (this.control.errors.hasOwnProperty(key)) {
          this.errors.push(this.translate.instant(key + "v"));
        }
      }
    });
    this.control.statusChanges.subscribe((value) => {
      this.control.markAsTouched();

      this.errors = [];
      for (const key in this.control.errors) {
        if (this.control.errors.hasOwnProperty(key)) {
          this.errors.push(this.translate.instant(key + "v"));
        }
      }
    });
    if (this.pH === undefined) {
      this.pH = "Enter " + this.text;
    }
    this.control.valueChanges.subscribe(() => {
      if (
        this.control.value === "" ||
        this.control.value === null ||
        this.control.value === undefined
      ) {
        this.innerValue = "";
        this.inputRef.nativeElement.value = "";
      }
    });
  }

  onChange(e: Event, value: any) {
    try {
      this.innerValue = value;
      this.propagateChange(this.innerValue);
      this.valueChanged.emit(this.innerValue);
      this.errors = [];
      for (const key in this.control.errors) {
        if (this.control.errors.hasOwnProperty(key)) {
          this.errors.push(this.translate.instant(key + "v"));
        }
      }
    } catch (e) {}
  }
  checkValid() {
    try {
      return (this.control as any).validator("").required == true;
    } catch (element) {
      return false;
    }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  propagateChange = (_: any) => {};

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onChange(null, this.innerValue);
  }
}
