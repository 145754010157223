/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ExecuteResponseData } from '../models/execute-response-data';
import { InputCreateClientInvoiceDto } from '../models/input-create-client-invoice-dto';
import { OutputGetClientInvoiceCustomerDetailsDto } from '../models/output-get-client-invoice-customer-details-dto';
import { PaymentStatus } from '../models/payment-status';

@Injectable({
  providedIn: 'root',
})
export class ClientInvoiceManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientInvoiceManagementCreateInvoice
   */
  static readonly ClientInvoiceManagementCreateInvoicePath = '/api/customer/ClientInvoiceManagement/CreateInvoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementCreateInvoice$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientInvoiceManagementCreateInvoice$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateClientInvoiceDto
  }
): Observable<StrictHttpResponse<ExecuteResponseData>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementCreateInvoicePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecuteResponseData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementCreateInvoice$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientInvoiceManagementCreateInvoice$Plain(params?: {
    context?: HttpContext
    body?: InputCreateClientInvoiceDto
  }
): Observable<ExecuteResponseData> {

    return this.clientInvoiceManagementCreateInvoice$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExecuteResponseData>) => r.body as ExecuteResponseData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementCreateInvoice()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientInvoiceManagementCreateInvoice$Response(params?: {
    context?: HttpContext
    body?: InputCreateClientInvoiceDto
  }
): Observable<StrictHttpResponse<ExecuteResponseData>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementCreateInvoicePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExecuteResponseData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementCreateInvoice$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientInvoiceManagementCreateInvoice(params?: {
    context?: HttpContext
    body?: InputCreateClientInvoiceDto
  }
): Observable<ExecuteResponseData> {

    return this.clientInvoiceManagementCreateInvoice$Response(params).pipe(
      map((r: StrictHttpResponse<ExecuteResponseData>) => r.body as ExecuteResponseData)
    );
  }

  /**
   * Path part for operation clientInvoiceManagementGetPaymentStatus
   */
  static readonly ClientInvoiceManagementGetPaymentStatusPath = '/api/customer/ClientInvoiceManagement/GetPaymentStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementGetPaymentStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetPaymentStatus$Plain$Response(params?: {
    keyType?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentStatus>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementGetPaymentStatusPath, 'get');
    if (params) {
      rb.query('keyType', params.keyType, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentStatus>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementGetPaymentStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetPaymentStatus$Plain(params?: {
    keyType?: string;
    context?: HttpContext
  }
): Observable<PaymentStatus> {

    return this.clientInvoiceManagementGetPaymentStatus$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentStatus>) => r.body as PaymentStatus)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementGetPaymentStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetPaymentStatus$Response(params?: {
    keyType?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentStatus>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementGetPaymentStatusPath, 'get');
    if (params) {
      rb.query('keyType', params.keyType, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentStatus>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementGetPaymentStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetPaymentStatus(params?: {
    keyType?: string;
    context?: HttpContext
  }
): Observable<PaymentStatus> {

    return this.clientInvoiceManagementGetPaymentStatus$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentStatus>) => r.body as PaymentStatus)
    );
  }

  /**
   * Path part for operation clientInvoiceManagementGetClientInvoice
   */
  static readonly ClientInvoiceManagementGetClientInvoicePath = '/api/customer/ClientInvoiceManagement/GetClientInvoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementGetClientInvoice$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoice$Plain$Response(params?: {
    InvoiceId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetClientInvoiceCustomerDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementGetClientInvoicePath, 'get');
    if (params) {
      rb.query('InvoiceId', params.InvoiceId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetClientInvoiceCustomerDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementGetClientInvoice$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoice$Plain(params?: {
    InvoiceId?: string;
    context?: HttpContext
  }
): Observable<OutputGetClientInvoiceCustomerDetailsDto> {

    return this.clientInvoiceManagementGetClientInvoice$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetClientInvoiceCustomerDetailsDto>) => r.body as OutputGetClientInvoiceCustomerDetailsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementGetClientInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoice$Response(params?: {
    InvoiceId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetClientInvoiceCustomerDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementGetClientInvoicePath, 'get');
    if (params) {
      rb.query('InvoiceId', params.InvoiceId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetClientInvoiceCustomerDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementGetClientInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoice(params?: {
    InvoiceId?: string;
    context?: HttpContext
  }
): Observable<OutputGetClientInvoiceCustomerDetailsDto> {

    return this.clientInvoiceManagementGetClientInvoice$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetClientInvoiceCustomerDetailsDto>) => r.body as OutputGetClientInvoiceCustomerDetailsDto)
    );
  }

  /**
   * Path part for operation clientInvoiceManagementGetClientInvoices
   */
  static readonly ClientInvoiceManagementGetClientInvoicesPath = '/api/customer/ClientInvoiceManagement/GetClientInvoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementGetClientInvoices$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoices$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetClientInvoiceCustomerDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementGetClientInvoicesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetClientInvoiceCustomerDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementGetClientInvoices$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoices$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetClientInvoiceCustomerDetailsDto>> {

    return this.clientInvoiceManagementGetClientInvoices$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetClientInvoiceCustomerDetailsDto>>) => r.body as Array<OutputGetClientInvoiceCustomerDetailsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientInvoiceManagementGetClientInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoices$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetClientInvoiceCustomerDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientInvoiceManagementService.ClientInvoiceManagementGetClientInvoicesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetClientInvoiceCustomerDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientInvoiceManagementGetClientInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientInvoiceManagementGetClientInvoices(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetClientInvoiceCustomerDetailsDto>> {

    return this.clientInvoiceManagementGetClientInvoices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetClientInvoiceCustomerDetailsDto>>) => r.body as Array<OutputGetClientInvoiceCustomerDetailsDto>)
    );
  }

}
