<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"
                ><i class="fa fa-sign-out" aria-hidden="true"></i>logout</a
              >
            </li>

            <li
              *ngIf="authService.isLogin()"
              class="onhover-dropdown mobile-account"
            >
              <i class="fa fa-user" aria-hidden="true"></i>
              {{ authService.getCurrentUserFirstName() }}
              <ul class="onhover-show-div">
                <li>
                  <a routerLink="/pages/profile"
                    >{{ "MY_PROFILE" | translate }}
                  </a>
                </li>
                <li>
                  <a routerLink="/pages/myOrders"
                    >{{ "MY_ORDERS" | translate }}
                  </a>
                </li>
                <li>
                  <a (click)="logout()">{{ "logout" | translate }} </a>
                </li>
              </ul>
            </li>

            <li
              *ngIf="!authService.isLogin()"
              routerLink="/pages/login"
              class="mobile-account"
            >
              <i class="fa fa-user" aria-hidden="true"></i>
              {{ authService.getCurrentUserFirstName() }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo" />
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
