import { Component, PLATFORM_ID, Inject, OnInit } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { CacheDataService } from "./shared/services/cache-data.service";
import { Router } from "@angular/router";
import { StylistAccountManagementService } from "./shared/api";
import { AuthService } from "./shared/services/AuthService";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    translate: TranslateService,

    private router: Router,
    private stylistManagmentService: StylistAccountManagementService,
    private authService: AuthService,
    public cacheDataService: CacheDataService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang("en");
      translate.addLangs(["en", "fr"]);
    }
    translate.setDefaultLang("en");
  }
  ngOnInit(): void {
    this.stylistManagmentService
      .stylistAccountManagementIsComplete()
      .subscribe((value) => {
        if (this.authService.isLogin()) {
          if (!value) {
            this.router.navigate(["/login/complete-profile"]);
          }
        } else {
          this.router.navigate(["/login"]);
        }
      });
  }
}
