import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Observable } from "rxjs";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { CartService } from "src/app/shared/services/cart.service";

@Component({
  selector: "app-cart-variation",
  templateUrl: "./cart-variation.component.html",
  styleUrls: ["./cart-variation.component.scss"],
})
export class CartVariationComponent implements OnInit, OnDestroy {
  @Input() direction: string = "right"; // Default Direction Right

  public products: Product[] = [];

  constructor(
    public productService: ProductService,
    public cartService: CartService
  ) {
    this.cartService.cartItems.subscribe(
      (response) => (this.products = response)
    );
  }

  ngOnInit(): void {
    this.cartService.OpenCart = false;
  }

  closeCart() {
    this.cartService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  removeItem(product: any) {
    this.cartService.removeFromCart(product);
  }

  ngOnDestroy(): void {
    this.closeCart();
  }
}
