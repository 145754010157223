import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingBarService } from "@ngx-loading-bar/core";

@Injectable({ providedIn: "root" })
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loadingBarService: LoadingBarService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var authReq ;
    if(req.url.includes('blob.core.windows')) {
      authReq = req.clone()
    }
    else{
      authReq = req.clone({
        headers: req.headers.set(
          "Authorization",
          "Bearer " +
            (localStorage.getItem("token") != null
              ? localStorage.getItem("token")
              : localStorage.getItem("tokenStylist"))
        ),
      });
    }
      
    let timeExeded = false;

    const time = setTimeout(() => {
      this.loadingBarService.start();
      timeExeded = true;
    }, 300);
    return next.handle(authReq).pipe(
      finalize(() => {
        if (!timeExeded) {
          clearTimeout(time);
        }
        this.loadingBarService.complete();
      })
    );
  }
}
