<div #canvacContainer class='canvas-wrapper'>
<div>
  <input [(colorPicker)]="color"
  (colorPickerChange)="onUpdateColor()" #inputColor style="width:0px ; visibility: hidden;" >
  <button type="button"  matTooltip="Color Picker" matTooltipPosition="above"   mat-icon-button (click)="inputColor.click()" >
  <i [style.color]="color" class="material-icons icons">color_lens</i>
  </button>
</div>
  <canvas id='canvas'></canvas>
</div>
