/* tslint:disable */
/* eslint-disable */
export enum TaskCreationOptions {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $4 = 4,
  $8 = 8,
  $16 = 16,
  $64 = 64
}
