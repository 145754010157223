/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateContactDto } from '../models/input-create-contact-dto';
import { OutputGetAboutUsDto } from '../models/output-get-about-us-dto';
import { OutputGetAllBlogsDto } from '../models/output-get-all-blogs-dto';
import { OutputGetAllBranchDto } from '../models/output-get-all-branch-dto';
import { OutputGetAllCityDto } from '../models/output-get-all-city-dto';
import { OutputGetAllColorDto } from '../models/output-get-all-color-dto';
import { OutputGetAllGovernorateDto } from '../models/output-get-all-governorate-dto';
import { OutputGetAllHomeSectionsDto } from '../models/output-get-all-home-sections-dto';
import { OutputGetAllSizeDto } from '../models/output-get-all-size-dto';
import { OutputGetAllSliderDto } from '../models/output-get-all-slider-dto';
import { OutputGetAllStylesDto } from '../models/output-get-all-styles-dto';
import { OutputGetBlogDetailsDto } from '../models/output-get-blog-details-dto';
import { OutputGetGenderAdsDto } from '../models/output-get-gender-ads-dto';
import { OutputGetSiteContent } from '../models/output-get-site-content';
import { OutputGetSliderDetailDto } from '../models/output-get-slider-detail-dto';
import { OutputGetStylistWorkDto } from '../models/output-get-stylist-work-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerLookupsManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation customerLookupsManagementGetGenderAds
   */
  static readonly CustomerLookupsManagementGetGenderAdsPath = '/api/customer/CustomerLookupsManagement/GetGenderAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetGenderAds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetGenderAds$Plain$Response(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetGenderAdsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetGenderAdsPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetGenderAdsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetGenderAds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetGenderAds$Plain(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<OutputGetGenderAdsDto> {

    return this.customerLookupsManagementGetGenderAds$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetGenderAdsDto>) => r.body as OutputGetGenderAdsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetGenderAds()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetGenderAds$Response(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetGenderAdsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetGenderAdsPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetGenderAdsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetGenderAds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetGenderAds(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<OutputGetGenderAdsDto> {

    return this.customerLookupsManagementGetGenderAds$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetGenderAdsDto>) => r.body as OutputGetGenderAdsDto)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAboutUs
   */
  static readonly CustomerLookupsManagementGetAboutUsPath = '/api/customer/CustomerLookupsManagement/GetAboutUs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAboutUs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAboutUs$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAboutUsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAboutUsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAboutUsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAboutUs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAboutUs$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAboutUsDto> {

    return this.customerLookupsManagementGetAboutUs$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAboutUsDto>) => r.body as OutputGetAboutUsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAboutUs()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAboutUs$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAboutUsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAboutUsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAboutUsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAboutUs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAboutUs(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAboutUsDto> {

    return this.customerLookupsManagementGetAboutUs$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAboutUsDto>) => r.body as OutputGetAboutUsDto)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetSiteContent
   */
  static readonly CustomerLookupsManagementGetSiteContentPath = '/api/customer/CustomerLookupsManagement/GetSiteContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetSiteContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSiteContent$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetSiteContent>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetSiteContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetSiteContent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetSiteContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSiteContent$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetSiteContent> {

    return this.customerLookupsManagementGetSiteContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetSiteContent>) => r.body as OutputGetSiteContent)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetSiteContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSiteContent$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetSiteContent>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetSiteContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetSiteContent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetSiteContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSiteContent(params?: {
    context?: HttpContext
  }
): Observable<OutputGetSiteContent> {

    return this.customerLookupsManagementGetSiteContent$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetSiteContent>) => r.body as OutputGetSiteContent)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllBlogs
   */
  static readonly CustomerLookupsManagementGetAllBlogsPath = '/api/customer/CustomerLookupsManagement/GetAllBlogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllBlogs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBlogs$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBlogsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllBlogsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBlogsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllBlogs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBlogs$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBlogsDto>> {

    return this.customerLookupsManagementGetAllBlogs$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBlogsDto>>) => r.body as Array<OutputGetAllBlogsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllBlogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBlogs$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBlogsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllBlogsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBlogsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllBlogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBlogs(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBlogsDto>> {

    return this.customerLookupsManagementGetAllBlogs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBlogsDto>>) => r.body as Array<OutputGetAllBlogsDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetBlogDetails
   */
  static readonly CustomerLookupsManagementGetBlogDetailsPath = '/api/customer/CustomerLookupsManagement/GetBlogDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetBlogDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetBlogDetails$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBlogDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetBlogDetailsPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBlogDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetBlogDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetBlogDetails$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutputGetBlogDetailsDto> {

    return this.customerLookupsManagementGetBlogDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBlogDetailsDto>) => r.body as OutputGetBlogDetailsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetBlogDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetBlogDetails$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBlogDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetBlogDetailsPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBlogDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetBlogDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetBlogDetails(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutputGetBlogDetailsDto> {

    return this.customerLookupsManagementGetBlogDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBlogDetailsDto>) => r.body as OutputGetBlogDetailsDto)
    );
  }

  /**
   * Path part for operation customerLookupsManagementCreateContact
   */
  static readonly CustomerLookupsManagementCreateContactPath = '/api/customer/CustomerLookupsManagement/CreateContact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementCreateContact$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerLookupsManagementCreateContact$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateContactDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementCreateContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementCreateContact$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerLookupsManagementCreateContact$Plain(params?: {
    context?: HttpContext
    body?: InputCreateContactDto
  }
): Observable<boolean> {

    return this.customerLookupsManagementCreateContact$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementCreateContact()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerLookupsManagementCreateContact$Response(params?: {
    context?: HttpContext
    body?: InputCreateContactDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementCreateContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementCreateContact$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerLookupsManagementCreateContact(params?: {
    context?: HttpContext
    body?: InputCreateContactDto
  }
): Observable<boolean> {

    return this.customerLookupsManagementCreateContact$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllCityByGovenorateId
   */
  static readonly CustomerLookupsManagementGetAllCityByGovenorateIdPath = '/api/customer/CustomerLookupsManagement/GetAllCityByGovenorateId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllCityByGovenorateId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllCityByGovenorateId$Plain$Response(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllCityByGovenorateIdPath, 'get');
    if (params) {
      rb.query('GovenorateId', params.GovenorateId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllCityByGovenorateId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllCityByGovenorateId$Plain(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCityDto>> {

    return this.customerLookupsManagementGetAllCityByGovenorateId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>) => r.body as Array<OutputGetAllCityDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllCityByGovenorateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllCityByGovenorateId$Response(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllCityByGovenorateIdPath, 'get');
    if (params) {
      rb.query('GovenorateId', params.GovenorateId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllCityByGovenorateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllCityByGovenorateId(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCityDto>> {

    return this.customerLookupsManagementGetAllCityByGovenorateId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>) => r.body as Array<OutputGetAllCityDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllBranchByCityId
   */
  static readonly CustomerLookupsManagementGetAllBranchByCityIdPath = '/api/customer/CustomerLookupsManagement/GetAllBranchByCityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllBranchByCityId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBranchByCityId$Plain$Response(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllBranchByCityIdPath, 'get');
    if (params) {
      rb.query('CityId', params.CityId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBranchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllBranchByCityId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBranchByCityId$Plain(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBranchDto>> {

    return this.customerLookupsManagementGetAllBranchByCityId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>) => r.body as Array<OutputGetAllBranchDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllBranchByCityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBranchByCityId$Response(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllBranchByCityIdPath, 'get');
    if (params) {
      rb.query('CityId', params.CityId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBranchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllBranchByCityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllBranchByCityId(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBranchDto>> {

    return this.customerLookupsManagementGetAllBranchByCityId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>) => r.body as Array<OutputGetAllBranchDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllGovernorate
   */
  static readonly CustomerLookupsManagementGetAllGovernoratePath = '/api/customer/CustomerLookupsManagement/GetAllGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllGovernorate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllGovernorate$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllGovernorateDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllGovernoratePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllGovernorateDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllGovernorate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllGovernorate$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllGovernorateDto>> {

    return this.customerLookupsManagementGetAllGovernorate$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGovernorateDto>>) => r.body as Array<OutputGetAllGovernorateDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllGovernorate()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllGovernorate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllGovernorateDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllGovernoratePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllGovernorateDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllGovernorate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllGovernorate(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllGovernorateDto>> {

    return this.customerLookupsManagementGetAllGovernorate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGovernorateDto>>) => r.body as Array<OutputGetAllGovernorateDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllSlider
   */
  static readonly CustomerLookupsManagementGetAllSliderPath = '/api/customer/CustomerLookupsManagement/GetAllSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllSlider$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSlider$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSliderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllSliderPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSliderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllSlider$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSlider$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSliderDto>> {

    return this.customerLookupsManagementGetAllSlider$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSliderDto>>) => r.body as Array<OutputGetAllSliderDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllSlider()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSlider$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSliderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllSliderPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSliderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllSlider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSlider(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSliderDto>> {

    return this.customerLookupsManagementGetAllSlider$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSliderDto>>) => r.body as Array<OutputGetAllSliderDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetSliderDetails
   */
  static readonly CustomerLookupsManagementGetSliderDetailsPath = '/api/customer/CustomerLookupsManagement/GetSliderDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetSliderDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSliderDetails$Plain$Response(params?: {
    SliderId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetSliderDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetSliderDetailsPath, 'get');
    if (params) {
      rb.query('SliderId', params.SliderId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetSliderDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetSliderDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSliderDetails$Plain(params?: {
    SliderId?: number;
    context?: HttpContext
  }
): Observable<OutputGetSliderDetailDto> {

    return this.customerLookupsManagementGetSliderDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetSliderDetailDto>) => r.body as OutputGetSliderDetailDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetSliderDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSliderDetails$Response(params?: {
    SliderId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetSliderDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetSliderDetailsPath, 'get');
    if (params) {
      rb.query('SliderId', params.SliderId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetSliderDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetSliderDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetSliderDetails(params?: {
    SliderId?: number;
    context?: HttpContext
  }
): Observable<OutputGetSliderDetailDto> {

    return this.customerLookupsManagementGetSliderDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetSliderDetailDto>) => r.body as OutputGetSliderDetailDto)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllSize
   */
  static readonly CustomerLookupsManagementGetAllSizePath = '/api/customer/CustomerLookupsManagement/GetAllSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllSize$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSize$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllSizePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllSize$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSize$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeDto>> {

    return this.customerLookupsManagementGetAllSize$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>) => r.body as Array<OutputGetAllSizeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSize$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllSizePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllSize(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeDto>> {

    return this.customerLookupsManagementGetAllSize$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>) => r.body as Array<OutputGetAllSizeDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllColor
   */
  static readonly CustomerLookupsManagementGetAllColorPath = '/api/customer/CustomerLookupsManagement/GetAllColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllColor$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllColor$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllColor$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllColor$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDto>> {

    return this.customerLookupsManagementGetAllColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>) => r.body as Array<OutputGetAllColorDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllColor()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllColor$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllColor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllColor(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDto>> {

    return this.customerLookupsManagementGetAllColor$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>) => r.body as Array<OutputGetAllColorDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllHomeSections
   */
  static readonly CustomerLookupsManagementGetAllHomeSectionsPath = '/api/customer/CustomerLookupsManagement/GetAllHomeSections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllHomeSections$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllHomeSections$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllHomeSectionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllHomeSections$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllHomeSections$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllHomeSectionsDto>> {

    return this.customerLookupsManagementGetAllHomeSections$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>) => r.body as Array<OutputGetAllHomeSectionsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllHomeSections()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllHomeSections$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllHomeSectionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllHomeSections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllHomeSections(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllHomeSectionsDto>> {

    return this.customerLookupsManagementGetAllHomeSections$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>) => r.body as Array<OutputGetAllHomeSectionsDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetStylistWorkDetails
   */
  static readonly CustomerLookupsManagementGetStylistWorkDetailsPath = '/api/customer/CustomerLookupsManagement/GetStylistWorkDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetStylistWorkDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkDetails$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistWorkDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetStylistWorkDetailsPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistWorkDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetStylistWorkDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkDetails$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetStylistWorkDto> {

    return this.customerLookupsManagementGetStylistWorkDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistWorkDto>) => r.body as OutputGetStylistWorkDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetStylistWorkDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkDetails$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistWorkDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetStylistWorkDetailsPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistWorkDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetStylistWorkDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkDetails(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetStylistWorkDto> {

    return this.customerLookupsManagementGetStylistWorkDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistWorkDto>) => r.body as OutputGetStylistWorkDto)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetStylistWorkForClient
   */
  static readonly CustomerLookupsManagementGetStylistWorkForClientPath = '/api/customer/CustomerLookupsManagement/GetStylistWorkForClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetStylistWorkForClient$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkForClient$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetStylistWorkForClientPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistWorkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetStylistWorkForClient$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkForClient$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistWorkDto>> {

    return this.customerLookupsManagementGetStylistWorkForClient$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>) => r.body as Array<OutputGetStylistWorkDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetStylistWorkForClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkForClient$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetStylistWorkForClientPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistWorkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetStylistWorkForClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetStylistWorkForClient(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistWorkDto>> {

    return this.customerLookupsManagementGetStylistWorkForClient$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>) => r.body as Array<OutputGetStylistWorkDto>)
    );
  }

  /**
   * Path part for operation customerLookupsManagementGetAllStyles
   */
  static readonly CustomerLookupsManagementGetAllStylesPath = '/api/customer/CustomerLookupsManagement/GetAllStyles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllStyles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllStyles$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllStylesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllStyles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllStyles$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylesDto>> {

    return this.customerLookupsManagementGetAllStyles$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>) => r.body as Array<OutputGetAllStylesDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerLookupsManagementGetAllStyles()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllStyles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLookupsManagementService.CustomerLookupsManagementGetAllStylesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerLookupsManagementGetAllStyles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerLookupsManagementGetAllStyles(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylesDto>> {

    return this.customerLookupsManagementGetAllStyles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>) => r.body as Array<OutputGetAllStylesDto>)
    );
  }

}
