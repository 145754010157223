<div class="select_input">
  <label for="{{ text }}">{{ text + (isRequired ? "*" : "") }}</label>
  <select
    #select
    [ngClass]="{ invalid: control.touched ? errors[0] : false }"
    class="form-control"
    (blur)="onChange($event, select.value)"
    (keyup)="onChange($event, select.value)"
    (change)="onChange($event, select.value)"
    (click)="onChange($event, select.value)"
    (focus)="touch()"
    [(ngModel)]="innerValue"
    size="1"
  >
    <option disabled value="">{{ text + (isRequired ? "*" : "") }}</option>
    <option *ngFor="let item of data" value="{{ item[Idvalue] }}">
      {{ item[Textvalue] }}
    </option>
  </select>
</div>

<div class="d-content" *ngIf="control.touched" class="text-danger">
  <p
    style="text-align: initial"
    class="text-danger"
    *ngFor="let error of errors"
  >
    {{ error }}
  </p>
</div>
