<button
  [ngStyle]="{ color: '#ffc111' }"
  style="border: none; background: transparent"
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  (click)="onClick(i + 1)"
  [ngbTooltip]="ratingId + 1"
>
  <i
    [ngStyle]="{ color: showIcon(i) === 'star' ? '#ffc111' : '#dddddd' }"
    class="fa fa-star"
  ></i>
</button>
<!-- <mat-error *ngIf="starCount == null || starCount == 0">
    Star count is <strong>required</strong> and cannot be zero
</mat-error> -->
<p class="body-2">
  Your rated <span class="body-2">{{ rating }}</span> /
  <span class="body-2">{{ starCount }}</span>
</p>
