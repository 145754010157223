<!-- side-bar single product slider start -->
<div *ngIf="products" class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container *ngFor="let product of products | slice: 0:3">
      <ng-template carouselSlide>
        <div>
          <div class="media" *ngFor="let product of products | slice: 0:3">
            <a
              [routerLink]="[
                '/shop/product/left/sidebar/',
                product.name.replace(' ', '-')
              ]"
            >
              <img
                class="img-fluid w-auto"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="product.itemImage[0]"
                alt=""
              />
            </a>
            <div class="media-body align-self-center">
              <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
              <a
                [routerLink]="[
                  '/shop/product/left/sidebar/',
                  product.name.replace(' ', '-')
                ]"
              >
                <h6>{{ product.name | titlecase }}</h6></a
              >

              <h4>
                {{
                  product?.price * currency?.price
                    | discount: product
                    | currency: currency?.currency:"symbol"
                }}
                <del *ngIf="product?.isOffer"
                  ><span class="money">
                    {{
                      product?.price * currency?.price
                        | currency: currency?.currency:"symbol"
                    }}</span
                  ></del
                >
              </h4>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div>
          <div class="media" *ngFor="let product of products | slice: 3:6">
            <a
              [routerLink]="[
                '/shop/product/left/sidebar/',
                product.name.replace(' ', '-')
              ]"
            >
              <img
                class="img-fluid w-auto"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="product.itemImage[0]"
                alt=""
              />
            </a>
            <div class="media-body align-self-center">
              <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
              <a
                [routerLink]="[
                  '/shop/product/left/sidebar/',
                  product.name.replace(' ', '-')
                ]"
              >
                <h6>{{ product.name | titlecase }}</h6></a
              >
              <h4>
                {{
                  product?.price * currency?.price
                    | discount: product
                    | currency: currency?.currency:"symbol"
                }}
                <del *ngIf="product?.isOffer"
                  ><span class="money">
                    {{
                      product?.price * currency?.price
                        | currency: currency?.currency:"symbol"
                    }}</span
                  ></del
                >
              </h4>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
