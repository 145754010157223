import { Injectable } from "@angular/core";

import jwt_decode from "jwt-decode";

import { resolve } from "url";

import { TranslateService } from "@ngx-translate/core";
import {
  ClientAccountManagementService,
  InputClientRefreshTokenDto,
  InputForgetPasswordDto,
  InputResetPasswordDto,
  InputSignInClientDto,
  InputSignInStylistDto,
  InputVerficationPasswordDto,
  StylistAccountManagementService,
} from "src/app/shared/api";
export const TOKEN_NAME = "token";
export const REFRESH_TOKEN_NAME = "refreshToken";
@Injectable()
export class AuthService {
  constructor(
    private stylistAccountManagementService: StylistAccountManagementService,
    private translate: TranslateService
  ) {
    if (this.isTokenExpired(localStorage.getItem(TOKEN_NAME))) {
      localStorage.removeItem(TOKEN_NAME);
    }
  }

  currentPage = 0;
  isLogin(): boolean {
    if (this.getToken() !== null && !this.isTokenExpired(this.getToken())) {
      return true;
    } else {
      return false;
    }
  }
  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }
  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token) as any;

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }
  getCurrentUserFirstName(): string {
    if (this.getToken()) {
      return (jwt_decode(this.getToken()) as any).given_name;
    } else {
      return this.translate.instant("myAccount");
    }
  }

  getCurrentUserImage(): string {
    if (this.getToken()) {
      return (jwt_decode(this.getToken()) as any).actort;
    } else {
      return null;
    }
  }
  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  login(inputSignInUserDto: InputSignInStylistDto): Promise<boolean> {
    return this.stylistAccountManagementService
      .stylistAccountManagementStylistSignIn({ body: inputSignInUserDto })
      .toPromise()
      .then(
        (value) => {
          if (!value) {
            return false;
          }
          this.setToken(value.token);
          this.setRefreshToken(value.refreshToken);
          this.refreshToken(value.token, value.refreshToken);
          return true;
        },
        (error) => {
          return false;
        }
      );
  }
  refreshToken(token, refreshToken) {
    const millisTill10 =
      new Date(this.getTokenExpirationDate(token)).getTime() -
      new Date().getTime();

    const time = setTimeout(() => {
      this.stylistAccountManagementService
        .stylistAccountManagementStylistToken({
          body: {
            token,
            refreshToken,
          } as InputClientRefreshTokenDto,
        })
        .subscribe(
          (value) => {
            this.setRefreshToken(value.refreshToken);
            this.setToken(value.token);
            this.setRefreshToken(value.refreshToken);
            this.refreshToken(value.token, value.refreshToken);
          },
          (error) => {
            clearTimeout(time);
          }
        );
    }, millisTill10);
  }
  refreshCurrentToken(): Promise<boolean> {
    if (this.getToken() == null) {
      return new Promise((value) => value(false));
    }
    return this.stylistAccountManagementService
      .stylistAccountManagementStylistToken({
        body: {
          token: this.getToken(),
          refreshToken: this.getRefreshToken(),
        } as InputClientRefreshTokenDto,
      })
      .toPromise()
      .then(
        (value) => {
          this.setRefreshToken(value.refreshToken);
          this.setToken(value.token);
          this.refreshToken(value.token, value.refreshToken);
          return true;
        },
        (error) => {
          return false;
        }
      );
  }
}
