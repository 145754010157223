<div class="toolbar-wrapper" dir="ltr">
  <div class="icon-panel">

    <button type="button"  mat-icon-button *ngIf="selectedToolType!=='MAIN' && selectedToolType!=='DEACTIVATE'" (click)="backToMainMenu()"><i class="material-icons icons">arrow_back</i></button>
    <button type="button"  mat-icon-button [disabled]="selection===undefined" *ngIf="selectedToolType==='MAIN' || selectedToolType==='DEACTIVATE'"
      (click)="cleanSelect()"><i class="material-icons" [ngClass]="{'icons':selection!==undefined}">do_not_disturb_alt</i></button>
    <button type="button"  mat-icon-button [disabled]="selection===undefined || selectionType==='group'" (click)="bringForward()"><i class="material-icons" [ngClass]="{'icons':selection!==undefined && selectionType!=='group'}">flip_to_front</i></button>
    <button type="button"  mat-icon-button [disabled]="selection===undefined || selectionType==='group'" (click)="sendBackward()"><i class="material-icons" [ngClass]="{'icons':selection!==undefined && selectionType!=='group'}">flip_to_back</i></button>
    
  </div>
  <div class="main-tool-slider">
    <app-main-tools [selectedToolType]="selectedToolType" *ngIf="selectedToolType==='MAIN' || selectedToolType==='DEACTIVATE'"></app-main-tools>
    <app-text-tools [selectedToolType]="selectedToolType" [activeObjectProps]="activeObjectProps" *ngIf="selectedToolType==='TEXT' || selectedToolType==='TEXT:EDITING'"></app-text-tools>
    <app-filter-tools [selectedToolType]="selectedToolType" [activeObjectProps]="activeObjectProps" *ngIf="selectedToolType==='FILTER:ALL' || selectedToolType==='FILTER:SINGLE'"></app-filter-tools>
    <app-crop-tools [selectedToolType]="selectedToolType" *ngIf="selectedToolType==='CROP'"></app-crop-tools>
    <app-shape-mask-tools [selectedToolType]="selectedToolType" [activeObjectProps]="activeObjectProps" *ngIf="selectedToolType==='SHAPE_MASK'"></app-shape-mask-tools>
    <app-pen-tools [selectedToolType]="selectedToolType" [activeObjectProps]="activeObjectProps" *ngIf="selectedToolType==='PEN'"></app-pen-tools>
  </div>
</div>