<!--footer section -->
<footer [class]="class">
  <section class="section-b-space light-layout">
    <div class="container">
      <div class="row footer-theme partition-f">
        <div class="col-lg-4 col-md-6">
          <div class="footer-title footer-mobile-title">
            <h4>about</h4>
          </div>
          <div
            class="footer-contant"
            *ngIf="cacheDataService.OutputGetSiteContent"
          >
            <div class="footer-logo">
              <img [src]="themeLogo" alt="logo" />
            </div>
            <p>
              {{
                cacheDataService.OutputGetSiteContent.aboutUsShortDescription
              }}
            </p>
            <div class="footer-social">
              <ul>
                <li>
                  <a href="{{ cacheDataService.OutputGetSiteContent.facebook }}"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="{{ cacheDataService.OutputGetSiteContent.youtube }}"
                    ><i class="fa fa-google-plus" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="{{ cacheDataService.OutputGetSiteContent.twitter }}"
                    ><i class="fa fa-twitter" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="{{ cacheDataService.OutputGetSiteContent.linkedIn }}"
                    ><i class="fa fa-linkedin" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col offset-xl-1">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Categories</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li
                  *ngFor="
                    let category of cacheDataService.OutputGetSiteContent.category.slice(
                      0,
                      6
                    )
                  "
                >
                  <a [routerLink]="['/shop/products/all/' + category.name]">{{
                    category.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>why we choose</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a href="javascript:void(0)">shipping & return</a></li>
                <li><a href="javascript:void(0)">secure shopping</a></li>
                <li><a href="javascript:void(0)">affiliates</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Contacts</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-list">
                <li>
                  <i class="fa fa-map-marker"></i
                  >{{ cacheDataService.OutputGetSiteContent.address }}
                </li>
                <li>
                  <i class="fa fa-phone"></i>Call Us:
                  {{ cacheDataService.OutputGetSiteContent.phone }}
                </li>
                <li>
                  <i class="fa fa-envelope-o"></i>Email Us:
                  <a> {{ cacheDataService.OutputGetSiteContent.email }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date : "y" }} RAWNAQ
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="payment-card-bottom">
            <ul>
              <li>
                <a><img src="assets/images/icon/visa.png" alt="" /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/mastercard.png" alt="" /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/paypal.png" alt="" /></a>
              </li>
              <li>
                <a
                  ><img src="assets/images/icon/american-express.png" alt=""
                /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/discover.png" alt="" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
