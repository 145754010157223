/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetAllStylistForClientListOutputPagedResponseDto } from '../models/get-all-stylist-for-client-list-output-paged-response-dto';
import { InputCreateClientBagDto } from '../models/input-create-client-bag-dto';
import { InputCreateCommentDto } from '../models/input-create-comment-dto';
import { InputCreateStylistRequest } from '../models/input-create-stylist-request';
import { InputCreateWishlistDto } from '../models/input-create-wishlist-dto';
import { InputFinishClientRequestItemDto } from '../models/input-finish-client-request-item-dto';
import { InputGetAllStylistByPackageIdDto } from '../models/input-get-all-stylist-by-package-id-dto';
import { OutputAllStylistsDto } from '../models/output-all-stylists-dto';
import { OutputGetAllNamesByGenderNameDto } from '../models/output-get-all-names-by-gender-name-dto';
import { OutputGetAllStylistPackageDto } from '../models/output-get-all-stylist-package-dto';
import { OutputGetAllStylistPersonQuestionDto } from '../models/output-get-all-stylist-person-question-dto';
import { OutputGetAllStylistServiceQuestionDto } from '../models/output-get-all-stylist-service-question-dto';
import { OutputGetAllWishlistsDto } from '../models/output-get-all-wishlists-dto';
import { OutputGetClientStylistRequestItem } from '../models/output-get-client-stylist-request-item';
import { OutputGetItemClientBagDto } from '../models/output-get-item-client-bag-dto';

@Injectable({
  providedIn: 'root',
})
export class ClientServiceManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientServiceManagementGetClientRequestItem
   */
  static readonly ClientServiceManagementGetClientRequestItemPath = '/api/customer/ClientServiceManagement/GetClientRequestItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetClientRequestItem$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetClientRequestItem$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetClientStylistRequestItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetClientRequestItemPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetClientStylistRequestItem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetClientRequestItem$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetClientRequestItem$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetClientStylistRequestItem>> {

    return this.clientServiceManagementGetClientRequestItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetClientStylistRequestItem>>) => r.body as Array<OutputGetClientStylistRequestItem>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetClientRequestItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetClientRequestItem$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetClientStylistRequestItem>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetClientRequestItemPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetClientStylistRequestItem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetClientRequestItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetClientRequestItem(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetClientStylistRequestItem>> {

    return this.clientServiceManagementGetClientRequestItem$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetClientStylistRequestItem>>) => r.body as Array<OutputGetClientStylistRequestItem>)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllStylists
   */
  static readonly ClientServiceManagementGetAllStylistsPath = '/api/customer/ClientServiceManagement/GetAllStylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylists$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylists$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.clientServiceManagementGetAllStylists$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylists$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylists(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.clientServiceManagementGetAllStylists$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllStylistsByPackageId
   */
  static readonly ClientServiceManagementGetAllStylistsByPackageIdPath = '/api/customer/ClientServiceManagement/GetAllStylistsByPackageId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistsByPackageId$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementGetAllStylistsByPackageId$Plain$Response(params?: {
    context?: HttpContext
    body?: InputGetAllStylistByPackageIdDto
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistsByPackageIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistsByPackageId$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementGetAllStylistsByPackageId$Plain(params?: {
    context?: HttpContext
    body?: InputGetAllStylistByPackageIdDto
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.clientServiceManagementGetAllStylistsByPackageId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistsByPackageId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementGetAllStylistsByPackageId$Response(params?: {
    context?: HttpContext
    body?: InputGetAllStylistByPackageIdDto
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistsByPackageIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistsByPackageId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementGetAllStylistsByPackageId(params?: {
    context?: HttpContext
    body?: InputGetAllStylistByPackageIdDto
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.clientServiceManagementGetAllStylistsByPackageId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * Path part for operation clientServiceManagementCreateStylistRequest
   */
  static readonly ClientServiceManagementCreateStylistRequestPath = '/api/customer/ClientServiceManagement/CreateStylistRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementCreateStylistRequest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementCreateStylistRequest$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistRequest
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementCreateStylistRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementCreateStylistRequest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementCreateStylistRequest$Plain(params?: {
    context?: HttpContext
    body?: InputCreateStylistRequest
  }
): Observable<boolean> {

    return this.clientServiceManagementCreateStylistRequest$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementCreateStylistRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementCreateStylistRequest$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistRequest
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementCreateStylistRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementCreateStylistRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementCreateStylistRequest(params?: {
    context?: HttpContext
    body?: InputCreateStylistRequest
  }
): Observable<boolean> {

    return this.clientServiceManagementCreateStylistRequest$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientServiceManagementFinishClientRequestItem
   */
  static readonly ClientServiceManagementFinishClientRequestItemPath = '/api/customer/ClientServiceManagement/FinishClientRequestItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementFinishClientRequestItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementFinishClientRequestItem$Plain$Response(params?: {
    context?: HttpContext
    body?: InputFinishClientRequestItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementFinishClientRequestItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementFinishClientRequestItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementFinishClientRequestItem$Plain(params?: {
    context?: HttpContext
    body?: InputFinishClientRequestItemDto
  }
): Observable<boolean> {

    return this.clientServiceManagementFinishClientRequestItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementFinishClientRequestItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementFinishClientRequestItem$Response(params?: {
    context?: HttpContext
    body?: InputFinishClientRequestItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementFinishClientRequestItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementFinishClientRequestItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementFinishClientRequestItem(params?: {
    context?: HttpContext
    body?: InputFinishClientRequestItemDto
  }
): Observable<boolean> {

    return this.clientServiceManagementFinishClientRequestItem$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllStylistPackage
   */
  static readonly ClientServiceManagementGetAllStylistPackagePath = '/api/customer/ClientServiceManagement/GetAllStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistPackage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistPackage$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistPackagePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistPackage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistPackage$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPackageDto>> {

    return this.clientServiceManagementGetAllStylistPackage$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>) => r.body as Array<OutputGetAllStylistPackageDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistPackage$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistPackagePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistPackage(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPackageDto>> {

    return this.clientServiceManagementGetAllStylistPackage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>) => r.body as Array<OutputGetAllStylistPackageDto>)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllStylistRequestQuestion
   */
  static readonly ClientServiceManagementGetAllStylistRequestQuestionPath = '/api/customer/ClientServiceManagement/GetAllStylistRequestQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistRequestQuestion$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestQuestion$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistRequestQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistRequestQuestion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestQuestion$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistServiceQuestionDto>> {

    return this.clientServiceManagementGetAllStylistRequestQuestion$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>) => r.body as Array<OutputGetAllStylistServiceQuestionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistRequestQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestQuestion$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistRequestQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistRequestQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestQuestion(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistServiceQuestionDto>> {

    return this.clientServiceManagementGetAllStylistRequestQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>) => r.body as Array<OutputGetAllStylistServiceQuestionDto>)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllStylistRequestPersonQuestion
   */
  static readonly ClientServiceManagementGetAllStylistRequestPersonQuestionPath = '/api/customer/ClientServiceManagement/GetAllStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistRequestPersonQuestion$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestPersonQuestion$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistRequestPersonQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistRequestPersonQuestion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestPersonQuestion$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPersonQuestionDto>> {

    return this.clientServiceManagementGetAllStylistRequestPersonQuestion$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>) => r.body as Array<OutputGetAllStylistPersonQuestionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllStylistRequestPersonQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestPersonQuestion$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllStylistRequestPersonQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllStylistRequestPersonQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllStylistRequestPersonQuestion(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPersonQuestionDto>> {

    return this.clientServiceManagementGetAllStylistRequestPersonQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>) => r.body as Array<OutputGetAllStylistPersonQuestionDto>)
    );
  }

  /**
   * Path part for operation clientServiceManagementgetAllStylistForClient
   */
  static readonly ClientServiceManagementgetAllStylistForClientPath = '/api/customer/ClientServiceManagement/getAllStylistForClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementgetAllStylistForClient$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementgetAllStylistForClient$Plain$Response(params?: {
    Search?: string;
    StylistPackageId?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetAllStylistForClientListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementgetAllStylistForClientPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {"style":"form"});
      rb.query('StylistPackageId', params.StylistPackageId, {"style":"form"});
      rb.query('SortingDirection', params.SortingDirection, {"style":"form"});
      rb.query('SortingExpression', params.SortingExpression, {"style":"form"});
      rb.query('PageNumber', params.PageNumber, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAllStylistForClientListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementgetAllStylistForClient$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementgetAllStylistForClient$Plain(params?: {
    Search?: string;
    StylistPackageId?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<GetAllStylistForClientListOutputPagedResponseDto> {

    return this.clientServiceManagementgetAllStylistForClient$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetAllStylistForClientListOutputPagedResponseDto>) => r.body as GetAllStylistForClientListOutputPagedResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementgetAllStylistForClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementgetAllStylistForClient$Response(params?: {
    Search?: string;
    StylistPackageId?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetAllStylistForClientListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementgetAllStylistForClientPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {"style":"form"});
      rb.query('StylistPackageId', params.StylistPackageId, {"style":"form"});
      rb.query('SortingDirection', params.SortingDirection, {"style":"form"});
      rb.query('SortingExpression', params.SortingExpression, {"style":"form"});
      rb.query('PageNumber', params.PageNumber, {"style":"form"});
      rb.query('PageSize', params.PageSize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAllStylistForClientListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementgetAllStylistForClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementgetAllStylistForClient(params?: {
    Search?: string;
    StylistPackageId?: number;
    SortingDirection?: number;
    SortingExpression?: string;
    PageNumber?: number;
    PageSize?: number;
    context?: HttpContext
  }
): Observable<GetAllStylistForClientListOutputPagedResponseDto> {

    return this.clientServiceManagementgetAllStylistForClient$Response(params).pipe(
      map((r: StrictHttpResponse<GetAllStylistForClientListOutputPagedResponseDto>) => r.body as GetAllStylistForClientListOutputPagedResponseDto)
    );
  }

  /**
   * Path part for operation clientServiceManagementCreateComment
   */
  static readonly ClientServiceManagementCreateCommentPath = '/api/customer/ClientServiceManagement/CreateComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementCreateComment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementCreateComment$Response(params?: {
    context?: HttpContext
    body?: InputCreateCommentDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementCreateCommentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementCreateComment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementCreateComment(params?: {
    context?: HttpContext
    body?: InputCreateCommentDto
  }
): Observable<void> {

    return this.clientServiceManagementCreateComment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation clientServiceManagementDeleteComment
   */
  static readonly ClientServiceManagementDeleteCommentPath = '/api/customer/ClientServiceManagement/DeleteComment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementDeleteComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementDeleteComment$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementDeleteCommentPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementDeleteComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementDeleteComment(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.clientServiceManagementDeleteComment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation clientServiceManagementUpdateBag
   */
  static readonly ClientServiceManagementUpdateBagPath = '/api/customer/ClientServiceManagement/UpdateBag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementUpdateBag$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateBag$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<InputCreateClientBagDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementUpdateBagPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementUpdateBag$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateBag$Plain(params?: {
    context?: HttpContext
    body?: Array<InputCreateClientBagDto>
  }
): Observable<boolean> {

    return this.clientServiceManagementUpdateBag$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementUpdateBag()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateBag$Response(params?: {
    context?: HttpContext
    body?: Array<InputCreateClientBagDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementUpdateBagPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementUpdateBag$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateBag(params?: {
    context?: HttpContext
    body?: Array<InputCreateClientBagDto>
  }
): Observable<boolean> {

    return this.clientServiceManagementUpdateBag$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllItemInBag
   */
  static readonly ClientServiceManagementGetAllItemInBagPath = '/api/customer/ClientServiceManagement/GetAllItemInBag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllItemInBag$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllItemInBag$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetItemClientBagDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllItemInBagPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetItemClientBagDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllItemInBag$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllItemInBag$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetItemClientBagDto>> {

    return this.clientServiceManagementGetAllItemInBag$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetItemClientBagDto>>) => r.body as Array<OutputGetItemClientBagDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllItemInBag()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllItemInBag$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetItemClientBagDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllItemInBagPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetItemClientBagDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllItemInBag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllItemInBag(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetItemClientBagDto>> {

    return this.clientServiceManagementGetAllItemInBag$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetItemClientBagDto>>) => r.body as Array<OutputGetItemClientBagDto>)
    );
  }

  /**
   * Path part for operation clientServiceManagementUpdateWishlist
   */
  static readonly ClientServiceManagementUpdateWishlistPath = '/api/customer/ClientServiceManagement/UpdateWishlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementUpdateWishlist$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateWishlist$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<InputCreateWishlistDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementUpdateWishlistPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementUpdateWishlist$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateWishlist$Plain(params?: {
    context?: HttpContext
    body?: Array<InputCreateWishlistDto>
  }
): Observable<boolean> {

    return this.clientServiceManagementUpdateWishlist$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementUpdateWishlist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateWishlist$Response(params?: {
    context?: HttpContext
    body?: Array<InputCreateWishlistDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementUpdateWishlistPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementUpdateWishlist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clientServiceManagementUpdateWishlist(params?: {
    context?: HttpContext
    body?: Array<InputCreateWishlistDto>
  }
): Observable<boolean> {

    return this.clientServiceManagementUpdateWishlist$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllWishlist
   */
  static readonly ClientServiceManagementGetAllWishlistPath = '/api/customer/ClientServiceManagement/GetAllWishlist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllWishlist$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllWishlist$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllWishlistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllWishlistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllWishlistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllWishlist$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllWishlist$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllWishlistsDto>> {

    return this.clientServiceManagementGetAllWishlist$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllWishlistsDto>>) => r.body as Array<OutputGetAllWishlistsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllWishlist()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllWishlist$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllWishlistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllWishlistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllWishlistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllWishlist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllWishlist(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllWishlistsDto>> {

    return this.clientServiceManagementGetAllWishlist$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllWishlistsDto>>) => r.body as Array<OutputGetAllWishlistsDto>)
    );
  }

  /**
   * Path part for operation clientServiceManagementGetAllGenderLinkByGenderName
   */
  static readonly ClientServiceManagementGetAllGenderLinkByGenderNamePath = '/api/customer/ClientServiceManagement/GetAllGenderLinkByGenderName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllGenderLinkByGenderName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllGenderLinkByGenderName$Plain$Response(params?: {
    genderName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllNamesByGenderNameDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllGenderLinkByGenderNamePath, 'get');
    if (params) {
      rb.query('genderName', params.genderName, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllNamesByGenderNameDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllGenderLinkByGenderName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllGenderLinkByGenderName$Plain(params?: {
    genderName?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllNamesByGenderNameDto>> {

    return this.clientServiceManagementGetAllGenderLinkByGenderName$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllNamesByGenderNameDto>>) => r.body as Array<OutputGetAllNamesByGenderNameDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientServiceManagementGetAllGenderLinkByGenderName()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllGenderLinkByGenderName$Response(params?: {
    genderName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllNamesByGenderNameDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ClientServiceManagementService.ClientServiceManagementGetAllGenderLinkByGenderNamePath, 'get');
    if (params) {
      rb.query('genderName', params.genderName, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllNamesByGenderNameDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `clientServiceManagementGetAllGenderLinkByGenderName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientServiceManagementGetAllGenderLinkByGenderName(params?: {
    genderName?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllNamesByGenderNameDto>> {

    return this.clientServiceManagementGetAllGenderLinkByGenderName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllNamesByGenderNameDto>>) => r.body as Array<OutputGetAllNamesByGenderNameDto>)
    );
  }

}
