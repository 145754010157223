/* tslint:disable */
/* eslint-disable */
export enum GenericParameterAttributes {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $8 = 8,
  $16 = 16,
  $28 = 28
}
