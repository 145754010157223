<div class="col-md-12 row p-0 m-0" >
 
  
  <div class="col-md-9">
      <app-canvas (fileSaved)="fileSaved.emit($event)"></app-canvas>
    </div>
    <div class="col-md-3">
      <app-image-picker [fileUrlList]="fileUrlList"></app-image-picker>
    </div>
  
  <div class="col-md-12">
    <app-toolbar></app-toolbar>
  </div>
</div>

