<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="collection.new">new</span>
      <span class="lable4" *ngIf="collection.sale">on sale</span> -->
    </div>
    <div class="front">
      <a [routerLink]="['/shop/collection/details/', collection?.id]">
        <img
          [defaultImage]="
            ImageSrc ? ImageSrc : 'assets/images/collection/placeholder.jpg'
          "
          [lazyLoad]="ImageSrc ? ImageSrc : collection.images[0]"
          class="img-fluid lazy-loading"
          alt="{{ collection.images[0] }}"
        />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/collection/details/', collection?.id]">
        <img
          [src]="ImageSrc ? ImageSrc : collection.images[1]"
          class="img-fluid lazy-loading"
          alt="{{ collection.images[1] }}"
        />
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li
        class="grid_thumb_img"
        [class.active]="ImageSrc == image"
        *ngFor="let image of collection.images"
      >
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">
          <img [lazyLoad]="image" />
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a
        href="javascript:void(0)"
        title="Add to cart"
        (click)="CartModal.openModal(collection)"
        *ngIf="cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Add to cart"
        (click)="addToCart(collection)"
        *ngIf="!cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Add to Wishlist"
        (click)="addToWishlist(collection)"
      >
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Quick View"
        (click)="QuickView.openModal()"
      >
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Compare"
        (click)="addToCompare(collection)"
      >
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="collection?.rating" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/shop/collection/details/', collection?.id]">
        <h6>{{ collection?.name | titlecase }}</h6>
      </a>
      <p>{{ collection?.name }}</p>
      <h4>
        {{
          collection?.price * currency?.price
            | discount: collection
            | currency: currency?.currency:"symbol"
        }}
      </h4>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-collection-quick-view
  #quickView
  [collectionId]="collection.id"
  [currency]="currency"
></app-collection-quick-view>
<app-cart-modal
  #cartModal
  [product]="collection"
  [currency]="currency"
  *ngIf="cartModal"
></app-cart-modal>
